import React, {Component} from 'react';
import axios from "axios";
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import '../Announcements/style.css';
import ReactTable from 'react-table';
import htmlToDraft from "html-to-draftjs";
import Select from "react-select";
import SweetAlert from "sweetalert-react";


class SliderCreate extends Component {

    constructor(props) {
        super(props);
        const {route} = this.props;

        this.state = {
            editorState: EditorState.createEmpty(),
            form: {
                image_url: '',
                content_type: 'image',
                header: '',
                description: '',
                order: 0,
                button_enable: 0,
                button_text: null,
                button_link: null,
                selectedFile:  null,
            },
            isFetching: false,
            contentTypes: [{value: 'image', label: 'Resim'}, {value: 'video', label: 'Video'}],

            showSwal: false,
            swalTitle: '',
            swalMessage: '',
            swalType: 'error',
            swalCallback: null
        }

        this.baseUri = 'https://service.todab.org.tr';

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleImageUploader = this.handleImageUploader.bind(this);

    }

    showSweetAlert(title, message, type, callback = null) {
        this.setState({
            showSwal: true,
            swalTitle: title,
            swalMessage: message,
            swalType: type,
            swalCallback: callback
        });
    }


    handleEditorChange(value) {
        console.log('value: ', value);
        this.setState({editorState: value})
    }


    handleFormInputChange(e) {
        const {name, value} = e.target;

        if (name === 'button_enable') {
            this.setState(prevVal => { let edited = prevVal.form; edited.button_enable = !edited.button_enable; return edited });
            return true;
        }

        this.setState(prevState => { let editedData = prevState.form; editedData[name] = value; return editedData});
    }

    handleImageUploader(e) {
        const file = e.target.files[0];

        this.setState(prevState => {
            let edited = prevState.form;
            edited.selectedFile = file;
            edited.loadedFile = 0;
            return {edited};
        });
    }

    handleSubmit() {
        const data = new FormData();
        if (this.state.form.selectedFile !== null && this.state.form.content_type === 'image') {
            data.append('file', this.state.form.selectedFile);
        }

        let content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState(prevState => {let x = prevState.form; x.description = content; return x;},

            () => {
                for (let [key, value] of Object.entries(this.state.form)) {
                    data.append(key, value);
                }

                // set type
                data.append('type', 'slider_add');

                console.log('DATA', data, this.state.form.selectedFile);

                axios.post(`${this.baseUri}/admin-add-slider`, data, {
                    headers: {
                        "Authorization": `Bearer ${sessionStorage.getItem('x-user-token') || false}`
                    }
                }).then(
                    response => {
                        if (response.data.status) {
                            this.showSweetAlert('Tebrikler', 'Yeni Slider Başarı İle Eklendi !', 'success', () => {
                                window.location.href = '/admin/sliders';
                            });
                        } else {
                            this.showSweetAlert('Hata', 'Lütfen Eksik Form Alanı Bırakmayınız.', 'error');
                        }
                    },
                    error => {
                        this.showSweetAlert('Hata', 'Lütfen Eksik Form Alanı Bırakmayınız.', 'error');
                    }
                );
            });
    }

    renderEditor() {
        const {editorState} = this.state;
        return (
            <>
                <label>Slider İçeriği:</label>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={this.handleEditorChange}
                />

            </>
        )
    }


    renderImageOrVideoSelector() {

        if (this.state.form.content_type === 'image') {
            return (
                <Form>
                    <Form.Label>Slider Resimi:</Form.Label>
                    <label style={{color:'red'}}>Aşağıdan Yeni Resim Seçebilirsiniz.</label>
                    <Form.Control type="file" placeholder="Resim" name={"image_url"}  onChange={this.handleImageUploader}/>
                </Form>
            );
        } else {
            return (
                <Form>
                    <Form.Label>Slider Video:</Form.Label>
                    <label style={{color:'red'}}>Video URL bağlantısı.</label>
                    <Form.Control type="text" placeholder="Slider Video URL" name={"image_url"}
                                  value={this.state.form.image_url}
                                  onChange={this.handleFormInputChange}
                    />
                </Form>)
        }
    }

    renderButtonFields() {
        if (this.state.form.button_enable) {
            return (
                <Form>
                    <Form.Group controlId="button_text">
                        <Form.Label>Button Yazısı</Form.Label>
                        <Form.Control type="text" placeholder="Button Yazısı" name={"button_text"}
                                      value={this.state.form.button_text}
                                      onChange={this.handleFormInputChange}
                        />
                    </Form.Group>
                    <br/>



                    <Form.Group controlId="button_link">
                        <Form.Label>Button Bağlantısı</Form.Label>
                        <Form.Control type="text" placeholder="Button Bağlantısı" name={"button_link"}
                                      value={this.state.form.button_link}
                                      onChange={this.handleFormInputChange}
                        />
                    </Form.Group>
                </Form>
            );
        } else {
            return (<></>);
        }
    }


    render() {
        return (
        <Container style={{marginTop:40}}>
            <SweetAlert
                show={this.state.showSwal}
                title={this.state.swalTitle}
                text={this.state.swalMessage}
                confirmButtonText={"Tamam"}
                type={this.state.swalType}
                onConfirm={() => this.setState({ showSwal: false }, () => {
                    if(this.state.swalCallback !== null) {
                        (this.state.swalCallback)();
                        //this.state.swallCallback();
                    }
                })}
            />

            <Row>
                <Col lg={12}>
                    <h3>Yeni Slider Oluştur</h3>
                    <br/>
                </Col>
            </Row>

            <Row>
                <Col lg={12} style={{marginTop:50}}>
                    <Form>
                        <Form.Group controlId="header">
                            <Form.Label>Slider Başlığı</Form.Label>
                            <Form.Control type="text" placeholder="Slider Başlığı" name={"header"}
                                          value={this.state.form.header}
                                          onChange={this.handleFormInputChange}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Slider Tipi</Form.Label>
                            <Select
                                placeholder={'Seçiniz...'}
                                value={this.state.contentTypes.filter(x => x.value === this.state.form.content_type)}
                                options={this.state.contentTypes}
                                onChange={(val) => {
                                    this.setState(prevVal => {
                                        let {form} = prevVal;
                                        form.content_type = val.value;
                                        return form;
                                    })
                                }}
                            />
                        </Form.Group>

                        <br/>

                        {
                            this.renderImageOrVideoSelector()
                        }


                        <br/>

                        <Form.Group controlId="order">
                            <Form.Label>Slider Sırası</Form.Label>
                            <Form.Control type="number" placeholder="Slider Sırası" name={"order"}
                                          value={this.state.form.order}
                                          onChange={this.handleFormInputChange}
                            />
                        </Form.Group>

                        <br/>

                        <Form.Group controlId="button_enable">
                            <Form.Check type="checkbox" label="Button Gözüksün mü?"
                                        name={'button_enable'} checked={this.state.form.button_enable}
                                        onChange={this.handleFormInputChange}
                            />
                        </Form.Group>


                        <br/>
                        {this.renderButtonFields()}



                    </Form>
                    <hr/>
                    <br/>
                </Col>


                <Col lg={12}>
                    {this.renderEditor()}
                    <br/>
                </Col>


                <Col lg={12}>
                    <Button variant={'success'} onClick={() => {this.handleSubmit()}}>Oluştur</Button>
                </Col>

            </Row>


        </Container>)
    }


}


export default SliderCreate;