import axios from 'axios';
import {
    ABORT_LOGIN_REQUEST,
    CHECK_LOGIN_REQUEST,
    END_LOGIN_REQUEST,
    START_LOGIN_REQUEST,
    REMOVE_LOGIN_DATA
} from '../constants/login-action-types';


const API_BASE_URL = 'https://service.todab.org.tr';

export function startLoginRequest(payload) {
    return { type: START_LOGIN_REQUEST, payload };
}

export function endLoginRequest(payload) {
    console.log('PAYLOAD', payload);
    return { type: END_LOGIN_REQUEST, payload};
}

export function abortLoginRequest(payload) {
    console.log('PAYLOAD !!!', payload);
    return { type: ABORT_LOGIN_REQUEST, payload}
}

export function removeLoginData(payload) {
    return {type: REMOVE_LOGIN_DATA, payload};
}

export function checkLoginRequest(payload) {
    return { type: CHECK_LOGIN_REQUEST, payload};
}


function checkLoginHandler(response) {
    return {
        isAuthenticated: (typeof response.user !== 'undefined'),
        userToken: sessionStorage.getItem('x-user-token') || false,
        roles: response.roles,
        isFetching: false,
    }
}

export function checkIsAdmin(state) {
    let adminRole = state.roles.filter((role) => {
       return role.name === 'yonetici';
    });

    return Object.keys(adminRole).length > 0;
}


export function postLoginRequest(formData) {

    return function (dispatch) {
        return axios.post(`${API_BASE_URL}/authenticate`, {eposta: formData.username, password: formData.password})
            .then(
                response =>  { dispatch(endLoginRequest(response.data))},
                error => dispatch(abortLoginRequest(error))
            )
    }

}



export function checkLogin() {
	console.log('GİRİŞ KONTROL EDİLİYOR');
    const token = sessionStorage.getItem('x-user-token');
    if (token !== null) {
        return function (dispatch) {
            return axios.get(`${API_BASE_URL}/check-authenticate?token=${token}`)
                        .then(
                            response => { dispatch(checkLoginRequest(checkLoginHandler(response.data)))},
                            error => dispatch(abortLoginRequest(error))
                        );
        }
    } else {
        return function (dispatch) {
            // giris yapilmamis demektir.
            // Sessionu sifirlayalim ::DD
            console.log('TOKEN YOK !!!');
            return new Promise((resolve, reject) => resolve(dispatch(removeLoginData())));
        }
    }

}
