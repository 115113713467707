import React, {Component} from 'react';
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

class Marquee extends Component {

    constructor (props) {
        super(props);

        this.state = {
            news: [],
            isFetching: false,
            removePopupShow: false,
            removeSuccessPopup: false,
            selectedNewsId: null,
            marqueeText: "",
            updateSuccess: false

        }

        this.baseUri = 'https://service.todab.org.tr';

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }


    getMarquee() {
        const token = sessionStorage.getItem('x-user-token') || false;
        this.setState({isFetching: true});

        axios.get(`${this.baseUri}/get-marquee-text?panel=1`).then(
            response => {
                console.log('RESPONSE DATA', response.data.data.marquee_text);
                this.setState({marqueeText: response.data.data.marquee_text})
            },
            error => console.log('ERROR', error)
        );

        this.setState({isFetching: false});
    }


    renderMarquee() {
        let list = [];

        list.push(<Card style={{ width: '100%', marginBottom:20 }}>
            <Row>
                <Col lg={12}>
                    <Card.Body>
                        <Card.Title>Kayan Yazı</Card.Title>
                        <Card.Text>
                            <i>(Lütfen ayrı ayrı gözükmesini istediğiniz cümleleri alt alta yazınız)</i>
                            <br/>
                            <br/>
                            <textarea rows={10} className={"form-control"} id="marqueeText" value={this.state.marqueeText} onChange={this.handleChange}></textarea>
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Row>
        </Card>);

        return list;
    }


    updateMarquee() {

        const data = new FormData();
        data.append('marquee_text', this.state.marqueeText);
        axios.post(`${this.baseUri}/marquee-set`, data, {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('x-user-token') || false}`
            }
        }).then(
            response => this.setState({updateSuccess: true}),
            error => console.log('ERROR', error)
        );


    }


    componentDidMount() {
        this.getMarquee();
    }

    render() {
        return (
            <Container style={{marginTop:20}}>
                <Row>
                    <Col lg={12}>
                        <h1>Kayan Yazı (Marquee) Yönetimi</h1>
                    </Col>

                </Row>

                <Row>
                    <Col lg={12}>
                        {this.renderMarquee()}
                    </Col>
                </Row>


                <Row style={{marginBottom:25}}>
                    <Col lg={12}>
                        {this.state.updateSuccess ?<span className={'text-success'}>Güncellendi !</span> : <></>}
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        <Button variant={'success'} onClick={() => {this.updateMarquee()}}>Oluştur</Button>

                    </Col>
                </Row>

            </Container>
        )
    }
}


export default Marquee;

