/* Eslint-disable */
import {
    START_LOGIN_REQUEST,
    END_LOGIN_REQUEST,
    ABORT_LOGIN_REQUEST,
    CHECK_LOGIN_REQUEST,
    REMOVE_LOGIN_DATA
} from '../constants/login-action-types';


import jwtDecode from 'jwt-decode';

import history from '../../routes/history';
import { checkIsAdmin } from '../actions/login';

const initialState = {
    isFetching: false,
    isAuthenticated: false,
    userToken: false,
    loginError: false,
    roles: []
};


function toRedirect() {
    console.log('HİSTORY', history);
    history.push('/backend/home');
    window.location.href = '/ssbackend/home';
    return {}
}

function toAdminRedirectOrBackendRedirect(state) {
    console.log('STATEEE', state);
    let isAdmin = checkIsAdmin(state);
    console.log('IS ADMIN', isAdmin);

    if (isAdmin) {
        return window.location.href = '/admin/home';
    } else if (state.isAuthenticated) {
        console.log('IS ADMINNN', isAdmin, state.isAuthenticated);
        //return window.location.href = '/backend/home';
    }

    return {};
}



function loginReducer(state = initialState, action, history) {


    switch (action.type) {
        case START_LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });

        case END_LOGIN_REQUEST:

            if (typeof action.payload.token !== false) {
                sessionStorage.setItem('x-user-token', action.payload.token);
                console.log( jwtDecode(action.payload.token || '') || []);
            }

            const decodedJWT = jwtDecode(action.payload.token || '') || [];


            return {...state,
                isAuthenticated: typeof action.payload.error === 'undefined',
                userToken: action.payload.token || false,
                roles: (decodedJWT || {}).roles || [],
                isFetching: false,
                pushBrowser: toAdminRedirectOrBackendRedirect({isAuthenticated: typeof action.payload.error === 'undefined', roles: (decodedJWT || {}).roles || []})
            }


        case ABORT_LOGIN_REQUEST:
            console.log('ABORTEDDD');
            return {...state, loginError:true, isFetching:false}

        case REMOVE_LOGIN_DATA:
            console.log('ABORTEDDD');
            return {...state, loginError:false, isFetching:false, isAuthenticated: false, userToken: false}

        case CHECK_LOGIN_REQUEST:
            console.log('VAROLAN STATE', state);
            console.log('ACTİON PAYLOADI:', action.payload);
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }


}



export default loginReducer;