export const START_GET_CONTACT_DATA = 'START_GET_CONTACT_DATA';
export const END_GET_CONTACT_DATA = 'END_GET_CONTACT_DATA';
export const ABORT_GET_CONTACT_DATA = 'ABORT_GET_CONTACT_DATA';


/** Stocks Functions **/
export const START_GET_STOCKS_DATA = 'START_GET_STOCKS_DATA';
export const END_GET_STOCKS_DATA = 'END_GET_STOCKS_DATA';
export const ABORT_GET_STOCKS_DATA = 'ABORT_GET_STOCKS_DATA';

export const START_UPDATE_STOCKS_DATA = 'START_UPDATE_STOCKS_DATA';
export const END_UPDATE_STOCKS_DATA = 'END_UPDATE_STOCKS_DATA';
export const ABORT_UPDATE_STOCKS_DATA = 'ABORT_UPDATE_STOCKS_DATA';
export const RESET_ABORT_STATE_STOCKS_DATA = 'RESET_ABORT_STATE_STOCKS_DATA';



export const START_DELETE_STOCKS_DATA = 'START_DELETE_STOCKS_DATA';
export const END_DELETE_STOCKS_DATA = 'END_DELETE_STOCKS_DATA';
export const ABORT_DELETE_STOCKS_DATA = 'ABORT_DELETE_STOCKS_DATA';
