import React, {Component} from 'react';
import {Container, Row, Col, Button, Form, Card} from 'react-bootstrap';
import axios from 'axios';

class Analytics extends Component {

    constructor (props) {
        super(props);

        this.state = {
            singleCount:0,
            multipleCount: 0,
        }

        this.baseUrl = 'https://service.todab.org.tr';
    }



    getAnalyticsData () {
        const token = sessionStorage.getItem('x-user-token') || false;

        axios.get(`${this.baseUrl}/get-admin-analytics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                this.setAnalyticsData(response.data);
            },

            error => console.log('ERRORRR')
        )
    }

    setAnalyticsData (data) {
        if (data.status && typeof data.data !== 'undefined') {
            if (typeof data.data.singleHit !== 'undefined') {
                this.setState({ singleCount: data.data.singleHit });
            }

            if (typeof data.data.multipleHit !== 'undefined') {
                this.setState({ multipleCount: data.data.multipleHit });
            }
        }
    }




    componentDidMount() {
        this.getAnalyticsData();
    }

    render () {
        return (
            <Container style={{marginTop:70}}>
                <Row>
                    <Col lg={6}>

                        <Card bg="danger" text="white" style={{ width: '18rem' }}>
                            <Card.Header>İstatistik</Card.Header>
                            <Card.Body>
                                <Card.Title>Tekil Ziyaretçi Sayısı</Card.Title>
                                <Card.Text>
                                    <h1>{this.state.singleCount}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <br/>
                    <Col lg={6}>
                        <Card bg="success" text="white" style={{ width: '18rem' }}>
                            <Card.Header>İstatistik</Card.Header>
                            <Card.Body>
                                <Card.Title>Toplam Ziyaret Sayısı</Card.Title>
                                <Card.Text>
                                    <h1>{this.state.multipleCount}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Analytics;