import Login from './../components/Login/Login';
import Home from './../components/Home/Home';
import ContactRequests from './../components/ContactRequests/ContactRequests';
import Stocks from '../components/Stocks/Stocks';
import NewStocks from '../components/Stocks/NewStocks';



// admin
import AdminHome from './../components/Admin/Home/Home';
import StockLists from './../components/Admin/StockLists/index';
import ContactRequestsAdmin from '../components/Admin/ContactRequests';
import DataManager from '../components/Admin/DataManager';
import SearchLogs from '../components/Admin/SearchLogs';
import News from '../components/Admin/News';
import NewsCreate from '../components/Admin/News/create';
import NewsEdit from '../components/Admin/News/edit';
import Announcement from '../components/Admin/Announcements';
import AnnouncementCreate from '../components/Admin/Announcements/create';
import AnnouncementEdit from '../components/Admin/Announcements/edit';
import Analytics from '../components/Admin/Analytics';
import Marquee from '../components/Admin/Marquee';
import SiteContactRequestsAdmin from "../components/Admin/SiteContactRequests";

import Slider from "../components/Admin/Slider";
import SliderEdit from "../components/Admin/Slider/edit";
import SliderCreate from "../components/Admin/Slider/create";

const routes = [
    {
      path :'/',
      component: Login,
      isPrivate: false
    },
    {
        path : '/login',
        component: Login,
        isPrivate: false
    },

    {
        path : '/backend/home',
        component: Home,
        isPrivate: true
    },
    {
        path : '/backend/contact-requests',
        component: ContactRequests,
        isPrivate: true
    },

    {
        path : '/backend/stocks',
        component: Stocks,
        isPrivate: true
    },

    {
        path : '/backend/new-stock',
        component: NewStocks,
        isPrivate: true
    },



    {
        path:'/admin/home',
        component: AdminHome,
        isPrivate: true
    },
    {
        path : '/admin/contact-requests',
        component: ContactRequestsAdmin,
        isPrivate: true
    },
    {
        path : '/admin/stock-lists',
        component: StockLists,
        isPrivate: true
    },
    {
        path : '/admin/data-manager',
        component: DataManager,
        isPrivate: true
    },

    {
        path: '/admin/search-logs',
        component: SearchLogs,
        isPrivate: true
    },

    {
        path: '/admin/analytics',
        component: Analytics,
        isPrivate: true
    },

    {
        path: '/admin/news',
        component: News,
        isPrivate: true
    },

    {
        path: '/admin/news/create',
        component: NewsCreate,
        isPrivate: true
    },

    {
        path: '/admin/news/edit/:id',
        component: NewsEdit,
        isPrivate: true
    },


    // announcements
    {
        path: '/admin/announcement',
        component: Announcement,
        isPrivate: true
    },

    {
        path: '/admin/announcement/create',
        component: AnnouncementCreate,
        isPrivate: true
    },

    {
        path: '/admin/announcement/edit/:id',
        component: AnnouncementEdit,
        isPrivate: true
    },

    {
        path: '/admin/marquee',
        component: Marquee,
        isPrivate: true
    },

    {
        path: '/admin/site-contacts',
        component: SiteContactRequestsAdmin,
        isPrivate: true
    },

    {
        path: '/admin/sliders',
        component: Slider,
        isPrivate: true
    },

    {
        path: '/admin/sliders/create',
        component: SliderCreate,
        isPrivate: true
    },

    {
        path: '/admin/sliders/edit/:sliderId',
        component: SliderEdit,
        isPrivate: true
    },



    /**
     * Marquee
     */

    {
        path: '/admin/marquee',
        component: Marquee,
        isPrivate: true
    },

];


export default routes;