import React, {Component} from 'react';
import {Container, Row, Col, Button, Badge, Form } from 'react-bootstrap';
import ReactTable from 'react-table';
import axios from 'axios';
import 'react-table/react-table.css';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import SweetAlert from 'sweetalert-react';


class StockLists extends Component {


    constructor (props) {
        super(props);

        this.btnRef = React.createRef();
        this.baseUrl = 'https://service.todab.org.tr';

        this.state = {
            data: [],
            originalData: [],

            cities: [],

            // kategori
            categories: [],

            // tur
            types: [],

            // cesit
            kinds: [],

            selectedCategoryId: 0,
            selectedTypeId: 0,
            selectedKindId: 0,

            inputValue: '',
            bayiId: 0,
            selectedCity: 0,
            filterOption: 'all',
            query: '',

	        removePopupShow: false,
	        removedRecordId: false,
	        removeSuccessPopup: false,
	        removeForce:false,
        };

        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeKind = this.handleChangeKind.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }


    getToken() {
        return sessionStorage.getItem('x-user-token') || false;
    }

    getExcel(additionalUrl = 'bayi-stok-grup-listesi') {
        let params = {excel: true};
        axios.get(`${this.baseUrl}/reports/${additionalUrl}`, {
            params: params,
            headers:
                {
                    'Authorization': `Bearer ${this.getToken()}`
                }
        })
             .then(
                 response => {
                     console.log('RESP !', response.data, response);
                     this.btnRef.current.href = response.data.path;
                     this.btnRef.current.innerText = response.data.path;
                     this.btnRef.current.click();
                 },
                 error => console.log('error', error)
             );
    }

    fetchData() {
        const token = sessionStorage.getItem('x-user-token') || false;

        if (!token) return false;


        axios.get('https://service.todab.org.tr/admin-stock-lists', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                if (typeof response.status !== 'undefined' && response.status) {
                    this.setState({data: response.data.data, originalData: response.data.data});
                } else {
                    // was error
                }
            },
            error => {
            	console.log('HATA', error, token);
            }
        );
    }


    fetchCities() {
        const token = sessionStorage.getItem('x-user-token') || false;
        axios.get(`https://service.todab.org.tr/get-cities`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                response.data.data.unshift({value: false, label: 'Lütfen Seçiniz'});
                return this.setState({cities: response.data.data});
            },
            error => {
                console.log('ERROR', error, token);
            }
        );
    }


	removeRecordHandler(stockId, isForce = false) {
		this.setState({
			removedRecordId: stockId,
			removePopupShow: true,
			removeForce: isForce
		});
	}

	removeRecord(stockId, isForce = false) {
		const token = sessionStorage.getItem('x-user-token') || false;

		if (isForce) {
			stockId += '&force=true';
		}

		axios.get(`https://service.todab.org.tr/admin-delete-user-stock?stock_id=`+stockId, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		}).then(
			response => {
				if (typeof response.data.status !== 'undefined') {
					let nowDeletedRecord = this.state.removedRecordId;

					let data = [...this.state.data];
					let originalDataX = [...this.state.originalData];

					data = data.filter((dataX) => {
						return dataX.id !== nowDeletedRecord
					});

					originalDataX = originalDataX.filter((dataY) => {
						return dataY.id !== nowDeletedRecord
					});

					this.setState({
							data,
							originalData: originalDataX,
							removePopupShow:false,
							removeSuccessPopup: true,
							removedRecordId: false,
							removeForce: false

						},
						// callback
						() => {

						});
				}
			},
			error => {
				console.log('ERROR', error);
			}
		);
	}


    handleFilter(e) {
        const {value, name} = e.target;

        this.setState({[name]: value}, () => { this.filterResults() });
    }

    renderTable() {

        const buildName = (rec) => {
            if(typeof rec === 'undefined') {
                return '-';
            }


            var kategoriCategory_name = "-";
            if (typeof rec.kategori_name !== 'undefined' && rec.kategori_name !== null &&  rec.kategori_name.hasOwnProperty('category_name')) {
                kategoriCategory_name = rec.kategori_name.category_name;
            }

            var turCategoryName = "";
            if (typeof rec.tur_name !== 'undefined' && rec.tur_name !== null && rec.tur_name.hasOwnProperty('category_name')) {
                turCategoryName = rec.tur_name.category_name;
            }

            var cesitCategoryName = "";
            if (typeof rec.cesit_name !== 'undefined' && rec.cesit_name !== null &&  rec.cesit_name.hasOwnProperty('category_name')) {
                cesitCategoryName = rec.cesit_name.category_name;
            }

            return `${kategoriCategory_name} - ${turCategoryName} - ${cesitCategoryName}`;
        }

        const columns = [
            {
                Header: 'Bayi No',
                accessor: 'bayi.uye_kod',
                id:' bayiNo'
            },
            {
                Header: 'Bayi Adı',
                accessor: 'bayi.unvani',
                id:' bayiUnvani'
            },
            {
                Header: 'Stok Adı',
                accessor: (d) => buildName(d),
                id:' stokAdi',
                width: 400
            },

            {
                Header: 'Miktar',
                accessor: 'miktar',
                id:'miktar'
            },

            {
                Header: 'Birim Fiyat',
                accessor: 'birim_fiyat',
                id:'birimFiyat'
            },

            {
                Header: 'Cep No Görsünler',
                accessor: (d) => d.telefon_goster === 0 ? (<i className={'fa fa-close'} /> ) : (<i className={'fa fa-check'} /> ),
                id:'cepNoGorsunler'
            },

            {
                Header: 'Sms Mail Atsınlar',
                accessor: (d) => d.sms_mail_gonder === 0 ? (<i className={'fa fa-close'} /> ) : (<i className={'fa fa-check'} /> ),
                id:'smsMail'
            },

            {
                Header: 'Eklenme Tarihi',
                accessor: 'created_at',
                id:'eklenme'
            },

            {
                Header: 'Durumu',
                accessor: (d) => d.deleted_at !== null ? (<span>{d.deleted_at}</span>) : (<span>Aktif</span>),
                id:'durumu',
                Cell: (row) => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            textAlign:'center',
                            color:  'white'
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                padding:'0px 10px',
                                backgroundColor:
                                    row.original.deleted_at !== null ?
                                        'crimson':
                                        'green',
                            }}
                        >
                            {row.value}
                        </div>
                    </div>
                )
            },

	        {
		        Header: 'İşlemler',
		        style: {textAlign:'center'},
		        accessor: d => {
			        return (<>
				        <Button variant={'danger'} onClick={ () => this.removeRecordHandler(d.id)}><i className="fa fa-trash"></i> Sil</Button>
				        <Button variant={'danger'} onClick={ () => this.removeRecordHandler(d.id, true)}><i className="fa fa-trash"></i> Kalıcı Sil</Button>
			        </>)
		        },
		        id: 'colActions'
	        }

        ];

        const langProps = {
            previousText: 'Geri',
            nextText: 'İleri',
            loadingText: 'Yükleniyor',
            noDataText: 'Kayıt Bulunamadı',
            pageText: 'Sayfa',
            ofText: 'toplam',
            rowsText: 'satır'
        };

        return (
            <ReactTable data={this.state.data} columns={columns} {...langProps} minRows={5} style={{minHeight:350, marginBottom:30}}
                        filterable={true} />
        )

    }


    // filter options & methods
    getCategories() {

        const BASE_URL = 'https://portal.todab.org.tr';

        axios.get(BASE_URL+'/t-api/get-kategori-options')
             .then(response => response.data, error => console.log('category-options-request err:', error))
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     console.log('DATA ARRAY', dataArray);

                     this.setState({
                         categories: dataArray
                     });
                 }

             })
	        .catch(err => console.log('catch err:' , err));
    }


    getTypes() {
        const {selectedCategoryId} = this.state;
        const BASE_URL = 'https://portal.todab.org.tr';

        if (!selectedCategoryId) return false;

        return axios.get(BASE_URL+'/t-api/get-tur-options/'+selectedCategoryId)
             .then(response => response.data)
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     console.log('DATA ARRAY', dataArray);

                     this.setState({
                         types: dataArray
                     });
                 }
             });
    }


    getKinds() {
        const {selectedTypeId} = this.state;
        const BASE_URL = 'https://portal.todab.org.tr';

        if (!selectedTypeId) return false;

        axios.get(BASE_URL+'/t-api/get-cesit-options/'+selectedTypeId)
             .then(response => response.data)
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     this.setState({
                         kinds: dataArray
                     });
                 }
             });
    }


    handleChangeCategory (e) {
        const {value}  = e.target;

        this.setState({
            selectedCategoryId: value,
            selectedTypeId: 0,
            selectedKindId: 0

        }, () => {
            this.getTypes();
            this.filterResults();
        });
    };


    handleChangeType (e) {
        const {value}  = e.target;

        this.setState({
            selectedTypeId: value,
            selectedKindId: 0
        }, () => {
            this.getKinds();
            this.filterResults();
        });
    }


    handleChangeKind(e) {
        const {value}  = e.target;
        this.setState({
            selectedKindId: value
        }, () => this.filterResults());
    }

    componentDidMount() {
        this.fetchData();
        this.getCategories();
        this.fetchCities();
    }

    filterColors = (inputValue) => {
       return inputValue;
    };

    loadOptions(val, callback){
    	console.log('BAYİ LİSTESİ ÇEKİLİYOR !!');

        const token = sessionStorage.getItem('x-user-token') || false;
        axios.get(`https://service.todab.org.tr/bayi-search?query=${val}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                response.data.data.unshift({value: false, label: 'Lütfen Seçiniz'});
                return callback(response.data.data);
            },
            error => {
                console.log('ERROR', error);
            }
        );

        /*setTimeout(() => {
            return ([
                { value: 'chocolate', label: 'Chocolate' },
                { value: 'strawberry', label: 'Strawberry' },
                { value: 'vanilla', label: 'Vanilla' }
            ]);
        }, 1000);*/
    };



    filterResults() {

        let originalData = [...this.state.originalData];

        if (parseInt(this.state.selectedCategoryId || 0) !== 0) {

            const selectedCatId = parseInt(this.state.selectedCategoryId || 0);
            originalData = originalData.filter(obj => {
                return obj.kategori === selectedCatId;
            });
        }

        if (parseInt(this.state.selectedTypeId || 0) !== 0) {
            const selectedTypeId = parseInt(this.state.selectedTypeId || 0);
            originalData = originalData.filter(obj => {
                return obj.tur === selectedTypeId;
            });
        }


        if (parseInt(this.state.selectedKindId || 0) !== 0) {
            const selectedKindId = parseInt(this.state.selectedKindId || 0);
            originalData = originalData.filter(obj => {
                return obj.cesit === selectedKindId;
            });
        }

        if (parseInt(this.state.bayiId || 0) !== 0) {
            const bayiId = parseInt(this.state.bayiId || 0);
            originalData = originalData.filter(obj => {
                return obj.bayi.id === bayiId;
            });
        }


        if (parseInt(this.state.selectedCity || 0) !== 0) {
            const cityId = parseInt(this.state.selectedCity || 0);
            originalData = originalData.filter(obj => {
                return obj.bayi.il === cityId;
            });
        }



        switch (this.state.filterOption) {
            case 'all':
                break;
            case 'active':
                originalData = originalData.filter(x => x.deleted_at === null);
                break;
            case 'deleted':
                originalData = originalData.filter(x => x.deleted_at !== null);
                break;
        }


        let value = this.state.query;
        originalData.filter(row => {
            let columns = [
                'miktar',
                'birim_fiyat',
                'bayi.unvani',
                'bayi.uye_kod',
                'tur_name.category_name',
                'cesit_name.category_name',
                'kategori_name.category_name',
            ];

            let isFind = false;
            columns.map(colName => {
                let colName1 = colName;
                let colName2 = false;

                if (colName.indexOf('.') !== -1) {
                    let splitted = colName.split('.');
                    colName1 = splitted[0];
                    colName2 = splitted[1];
                }

                if (!isFind) {
                    try {
                        let currentCell = false;
                        if (colName2 !== false) {
                            currentCell = row[colName1][colName2];
                        } else {
                            currentCell = row[colName1];
                        }

                        isFind = (currentCell || '').toLocaleLowerCase('tr').indexOf(value.toLocaleLowerCase('tr')) !== -1;

                    } catch (exception) {
                        // was error
                    }
                }
            });

            return isFind;
        });

        this.setState({data: originalData});
    }

    render() {
        return (
            <Container fluid style={{marginTop:50}}>
	            <SweetAlert
		            show={this.state.removePopupShow}
		            title="Uyarı"
		            text="Bu Stok Kaydını Silmek İstediğinize Emin misiniz?"
		            showCancelButton
		            confirmButtonText={'Sil'}
		            cancelButtonText={'Vazgeç'}
		            onConfirm={() => this.setState({ removePopupShow: false }, () => this.removeRecord(this.state.removedRecordId, this.state.removeForce))}
		            onCancel={() => this.setState({removePopupShow:false, removeForce: false})}
	            />

                <Row>
                    <Col>
                        <h2>Stok Listesi <Badge variant={"secondary"}>Tümü</Badge></h2>
                    </Col>
                </Row>

                <br/><br/>

                <Row>
                    <Col lg={3}>
                        <Form.Group controlId="kategoriTur">
                            <Form.Label>Kategori</Form.Label>
                            <Form.Control as="select"  onChange={this.handleChangeCategory} value={this.state.selectedCategoryId}>
                                <option value={0}> Lütfen Seçiniz </option>

                                {
                                    _.map(this.state.categories, (category) => {
                                        return <option value={category.id}> {category.name} </option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col lg={3}>
                        <Form.Group controlId="tur">
                            <Form.Label>Tür</Form.Label>
                            <Form.Control as="select"  onChange={this.handleChangeType}  value={this.state.selectedTypeId}>
                                <option value={0}> Lütfen Seçiniz </option>
                                {
                                    _.map(this.state.types, (type) => {
                                        return <option value={type.id}> {type.name} </option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>


                    <Col lg={3}>
                        <Form.Group controlId="cesitCtl">
                            <Form.Label>Çeşit</Form.Label>
                            <Form.Control as="select" value={this.state.selectedKindId} onChange={this.handleChangeKind}>
                                <option value={0}> Lütfen Seçiniz </option>
                                {
                                    _.map(this.state.kinds, (kind) => {
                                        return <option value={kind.id}> {kind.name} </option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>


                    <Col lg={3}>
                        <Form.Group controlId="bayiCtl">
                            <Form.Label>Bayi</Form.Label>
                            <AsyncSelect
                                placeholder={'Bayi Seçiniz...'}
                                cacheOptions
                                loadOptions={this.loadOptions}
                                defaultOptions
                                onInputChange={(val) => {this.setState({inputValue: val })}}
                                onChange={(val) => { this.setState({bayiId: val.value}, () => {this.filterResults()})}}
                            />
                        </Form.Group>
                    </Col>

                </Row>

                <Row>
                    <Col lg={3}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Aktif - Silinmiş</Form.Label>
                            <Form.Control as="select" onChange={(e) => this.handleFilter(e)} name={'filterOption'}>
                                <option value={'all'} selected>Hepsi</option>
                                <option value={'active'}>Sadece Aktif Kayıtlar</option>
                                <option value={'deleted'}>Sadece Silinmiş Kayıtlar</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col lg={3}>
                        <Form.Group controlId="ctrlId">
                            <Form.Label>Arama Yapın: </Form.Label>
                            <Form.Control type="text" name={'query'} placeholder="Arayın..." onChange={(e) => this.handleFilter(e)} />
                        </Form.Group>
                    </Col>

                    <Col lg={3}>
                        <Form.Group controlId="ilCtrl">
                            <Form.Label>İl </Form.Label>
                            <Select
                                placeholder={'Seçiniz...'}
                                options={this.state.cities}
                                onChange={(val) => {
                                    this.setState({selectedCity: val.value}, () => {
                                        this.filterResults();
                                    })
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={2}>
                        <Form.Group>
                            <br/>
                            <Button variant={'success'} onClick={() => { this.getExcel() }}>Bayi Çıktı Al</Button>
                            &nbsp;
                            <Button variant={'success'} onClick={() => { this.getExcel('basit-stok-listesi') }}>Detaylı Çıktı Al</Button>
                            <a style={{display:'none'}} ref={this.btnRef}>Bas</a>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        { this.renderTable() }
                    </Col>
                </Row>
            </Container>
        )
    }

}



export default StockLists;
