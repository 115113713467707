import React, {Component} from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import axios from 'axios';
import ReactTable from 'react-table';
import _ from 'lodash';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import addHours from 'date-fns/add_hours'


class SearchLogs extends Component {

    constructor (props) {
        super (props);
        this.baseUrl = 'https://service.todab.org.tr';

        this.state = {
            data: [],
            totalRecords: 0,

            keyword: '',
            categories: [],
            types: [],
            kinds: [],
            selectedCategoryId: -1,
            selectedTypeId: -1,
            selectedKindId: -1,

            cities: [],
            provinces: [],
            selectedCityId: -1,
            selectedProvinceId: -1,

            startDate: 0,
            endDate: 0
        }


        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeKind = this.handleChangeKind.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeProvince = this.handleChangeProvince.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);

        this.btnRef = React.createRef();
    }


    getSearchParams() {
       return {
            keyword: this.state.keyword,
            category: this.state.selectedCategoryId,
            type: this.state.selectedTypeId,
            kind: this.state.selectedKindId,
            city: this.state.selectedCityId,
            province: this.state.selectedProvinceId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
    }


    getExcel() {
        let params = this.getSearchParams();
        params.excel = 'true';
        axios.get(`${this.baseUrl}/search-query-logs`, {
            params: params,
            headers:
                {
                    'Authorization': `Bearer ${this.getToken()}`
                }
        })
             .then(
                 response => {
                     this.btnRef.current.href = response.data.file;
                     this.btnRef.current.innerText = response.data.name;
                     this.btnRef.current.click();
                 },
                 error => console.log('error', error)
             );
    }

    // user tokenini alir.
    getToken() {
        return sessionStorage.getItem('x-user-token') || false;
    }

    // datayi state e pushlar.
    setLogs(data) {
        this.setState({data});
    }

    setTotalCount(count) {
        this.setState({totalRecords: count});
    }


    // api dan loglari getirir.
    getLogs() {
        let params = this.getSearchParams();
       axios.get(`${this.baseUrl}/search-query-logs`, {
           params: params,
           headers:
               {
                   'Authorization': `Bearer ${this.getToken()}`
               }
       })
            .then(
               response => { this.setLogs(response.data.data); this.setTotalCount(response.data.total)},
               error => console.log('error', error)
           );
    }


    // Tabloyu render eder.
    renderTable() {

        const accessParents = (obj, propertyName) => {
            if (obj.parents[propertyName] !== null) {
                return obj.parents[propertyName].category_name;
            }

            return '-';
        }

        const columns = [
            {
                Header: 'IP Adresi',
                accessor: 'ip',
            },
            {
                Header: 'Aranan Kelime',
                accessor: 'kelime',
            },
            {
                Header: 'Kategori',
                id: 'kategoriId',
                accessor: (d) => {
                   return accessParents(d, 'category')
                } ,
            },
            {
                Header: 'Tür',
                id: 'turId',
                accessor: (d) => {
                    return accessParents(d, 'type')
                } ,
            },
            {
                Header: 'Çeşit',
                id: 'cesitId',
                accessor: (d) => {
                    return accessParents(d, 'kind')
                } ,
            },
            {
                Header: 'İl',
                id: 'ilId',
                accessor: (d) => {
                    if (d.il_data !== null) {
                        return d.il_data.baslik;
                    }

                    return '-';
                } ,
            },
            {
                Header: 'İlçe',
                id: 'ilceId',
                accessor: (d) => {
                    if (d.ilce_data !== null && (d.ilce !== 0 && d.ilce !== null)) {
                        return d.ilce_data.baslik;
                    }

                    return '-';
                } ,
            },
            {
                Header: 'Sorgu Tarihi',
                id: 'createdAt',
                accessor: 'created_at',
            }
        ];

        const langProps = {
            previousText: 'Geri',
            nextText: 'İleri',
            loadingText: 'Yükleniyor',
            noDataText: 'Kayıt Bulunamadı',
            pageText: 'Sayfa',
            ofText: 'toplam',
            rowsText: 'satır'
        };


        return (<><ReactTable {...langProps}
                            columns={columns}
                            data={this.state.data}
                            minRows={5}
                            maxRows={20}
                            style={{minHeight:450, marginBottom:30}}
                            filterable={true}
                />
            <h4>Toplam Kayıt Sayısı: { this.state.totalRecords }</h4>
            <br/>
        </>);
    }


    getCategoryData(type, parentId = false, params) {
        const BASE_URL = 'https://portal.todab.org.tr';
        const types = {
            kategori: 'categories',
            tur: 'types',
            cesit: 'kinds'
        };


        axios.get(`${BASE_URL}/t-api/get-${type}-options${parentId !== false ? '/'+parentId : ''}`)
            .then(
                response => {
                    let data = response.data;
                    if (data.status) {
                        let dataArray = [];

                        Object.keys(data.data).map(key => {
                            dataArray.push({id: key, name: data.data[key]});
                            return key;
                        });

                        this.setState({
                            [types[type]]: dataArray
                        });
                    }
                }
            )
    }

    handleChangeCategory(e) {
        const {value} = e.target;

        this.setState({selectedCategoryId: parseInt(value || 0), selectedKindId:-1, selectedTypeId:-1, types: [], kinds: []}, () => {
           this.getCategoryData('tur', value);
        });
    }

    handleChangeType(e) {
        const {value} = e.target;

        this.setState({selectedTypeId: parseInt(value || -1),  selectedKindId:-1, kinds:[]}, () => {
           this.getCategoryData('cesit', value);
        });
    }

    handleChangeKind(e) {
        const {value} = e.target;

        console.log('VALUE', value);
        this.setState({selectedKindId: parseInt(value || -1)});
    }


    getCitiesData(cityId = false) {
        const token = sessionStorage.getItem('x-user-token') || false;
        let params = '';
        let stateName = 'cities';
        if (cityId !== false) {
            params = '?il_id='+cityId;
            stateName = 'provinces';
        }

        axios.get(`https://service.todab.org.tr/get-cities${params}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
             .then(
                 response => this.setState({[stateName]: response.data.data}),
                 error => console.log('ERROR ', error)
             );
    }

    handleChangeCity(e) {
        const {value} = e.target;
        this.setState({
            selectedCityId: value,
            selectedProvinceId: -1
        }, () => {
            this.getCitiesData(value);
        });
    }

    handleChangeProvince(e) {
        const {value} = e.target;
        this.setState({
            selectedProvinceId: value
        });
    }

    handleChangeDate(date, type) {
        date = addHours(date, 4);

        this.setState({
            [type]: date
        });
    }

    handleChangeKeyword(e) {
        const {value} = e.target;
        this.setState({keyword: value});
    }

    componentDidMount(){
        this.getLogs();
        this.getCategoryData('kategori');
        this.getCitiesData();
    }

    render() {
        return (
            <Container style={{marginTop:70}}>
                <Row>
                    <Col>
                        <h3>Arama Kayıtları</h3>
                        <br/>
                    </Col>
                </Row>


                <Row>
                    <Col lg={3}>
                        <Form.Group controlId="searchQuery">
                            <Form.Label>Aranan Kelime:</Form.Label>
                            <Form.Control type="text" placeholder="Aranan Kelime..."  value={this.state.keyword} onChange={this.handleChangeKeyword}/>
                        </Form.Group>
                    </Col>

                    <Col lg={3}>
                        <Form>
                            <Form.Group controlId="category.select">
                                <Form.Label>Kategori:</Form.Label>
                                <Form.Control as="select" onChange={this.handleChangeCategory} value={this.state.selectedCategoryId}>
                                    <option value={-1}>Lütfen Seçiniz</option>
                                    {
                                        _.map(this.state.categories, (record) => {
                                            return <option value={record.id}>{record.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={3}>
                        <Form>
                            <Form.Group controlId="type.select">
                                <Form.Label>Tür:</Form.Label>
                                <Form.Control as="select" onChange={this.handleChangeType} value={this.state.selectedTypeId}>
                                    <option value={-1}>Lütfen Seçiniz</option>
                                    {
                                        _.map(this.state.types, (record) => {
                                            return <option value={record.id}>{record.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={3}>
                        <Form>
                            <Form.Group controlId="kind.select">
                                <Form.Label>Çeşit:</Form.Label>
                                <Form.Control as="select" value={this.state.selectedKindId} onChange={this.handleChangeKind}>
                                    <option value={-1}>Lütfen Seçiniz</option>
                                    {
                                        _.map(this.state.kinds, (record) => {
                                            return <option value={record.id}>{record.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={3}>
                        <Form>
                            <Form.Group controlId="city.select">
                                <Form.Label>İl:</Form.Label>
                                <Form.Control as="select" value={this.state.selectedCityId} onChange={this.handleChangeCity}>
                                    <option value={-1}>Lütfen Seçiniz</option>
                                    {
                                        _.map(this.state.cities, (record) => {
                                            return <option value={record.value}>{record.label}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={3}>
                        <Form>
                            <Form.Group controlId="province.select">
                                <Form.Label>İlçe:</Form.Label>
                                <Form.Control as="select" value={this.state.selectedProvinceId} onChange={this.handleChangeProvince}>
                                    <option value={-1}>Lütfen Seçiniz</option>
                                    {
                                        _.map(this.state.provinces, (record) => {
                                            return <option value={record.value}>{record.label}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={2}>
                        <Form>
                            <Form.Group controlId="date.start">
                                <Form.Label>Başlangıç Tarihi:</Form.Label>
                                <DatePicker
                                    dateFormat={'yyyy.MM.dd'}
                                    selected={this.state.startDate}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={(date) => this.handleChangeDate(date, 'startDate')}
                                />
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={2}>
                        <Form>
                            <Form.Group controlId="date.end">
                                <Form.Label>Bitiş Tarihi:</Form.Label>
                                <br/>
                                <DatePicker
                                    dateFormat={'yyyy.MM.dd'}
                                    selected={this.state.endDate}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={(date) => this.handleChangeDate(date, 'endDate')}
                                />
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col lg={2}>
                        <Button style={{marginTop:22}} variant={'success'} onClick={() => this.getLogs()}>Filtrele</Button>
                        &nbsp;
                        <Button style={{marginTop:22}} variant={'success'} onClick={() => this.getExcel()}>Excel</Button>
                        <a style={{display:'none'}} ref={this.btnRef}>Bas</a>
                    </Col>


                </Row>

                <br/>

                <Row>
                    <Col>
                        { this.renderTable() }
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SearchLogs;
