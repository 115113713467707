import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row,Col, Card, Table} from 'react-bootstrap';
import {postContactRequests} from './../../redux/actions/user';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class ContactRequests extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.getContacts();
    }


    renderContactRequests() {
        let template = [];
        const buildName = (rec) => {
        	if (typeof rec.stock !== 'undefined' && rec.stock !== null) {
	            if (typeof rec.stock.kategori_name !== 'undefined') {
	                if (typeof rec.stock.tur_name !== 'undefined') {
	                    if (typeof rec.stock.cesit_name !== 'undefined') {
					        return `${rec.stock.kategori_name.category_name} - ${rec.stock.tur_name.category_name} - ${rec.stock.cesit_name.category_name}`;
				        }
			        }
		        }
	        }

	        return '-';
        }

        const columns = [
            {
                Header: 'Tohum Adı',
                id: 'tohumAdi',
                accessor: d => buildName(d),
                width: 350
            },

            {
                Header: 'Miktar',
                id: 'miktar',
                accessor: d => d.stock !== null ? d.stock.miktar : '-'
            },

            {
                Header: 'Birim Fiyat',
                id: 'birimFiyat',
                accessor: d => d.stock !== null ? `${d.stock.birim_fiyat} ₺` : '-'
            },

            {
                Header: 'Gönderen Kişi',
                accessor: 'gonderen_adsoyad'
            },

            {
                Header: 'Telefonu',
                accessor: 'gonderen_telefon'
            },

            {
                Header: 'Email Adresi',
                accessor: 'gonderen_mail'
            },

            {
                Header: 'Mesajı',
                accessor: 'mesaj'
            },

            {
                Header: 'Tarih',
                accessor: 'created_at'
            }
        ];

        const langProps = {
            previousText: 'Geri',
            nextText: 'İleri',
            loadingText: 'Yükleniyor',
            noDataText: 'Kayıt Bulunamadı',
            pageText: 'Sayfa',
            ofText: 'toplam',
            rowsText: 'satır'
        };

        return (
            <ReactTable style={{height:350}} {...langProps} minRows={5}
                        data={this.props.user.contacts}
                        columns={columns}
            />
        );
    }

    render () {
        return (
            <Container fluid style={{marginTop:20}}>
                <Row>
                    <Col>
                        <h3 align="center">Tohum Nerede - İletişim İstekleri</h3>
                        <br/>
                        {this.renderContactRequests()}
                    </Col>
                </Row>
            </Container>
        );
    }

}



const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getContacts: () => dispatch(postContactRequests())
    }
}


ContactRequests = connect(mapStateToProps, mapDispatchToProps)(ContactRequests);

export default ContactRequests;
