import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Button } from 'react-bootstrap';


class Home extends Component{


    constructor (props) {
        super(props);
    }


    render() {

        return (
           <Container style={{marginTop:40}}>
               <Row>
                   <Col>
                       <Button onClick={() => window.location.href = '/admin/data-manager'} variant="success">
                           Kategori - Tür Yönetimi
                       </Button>
                   </Col>

                   <Col>
                       <Button onClick={() => window.location.href = '/admin/stock-lists'} variant="warning">
                           Stok Listesi
                       </Button>
                   </Col>

                   <Col>
                       <Button onClick={() => window.location.href = '/admin/contact-requests'} variant="danger">
                           İletişim İstekleri
                       </Button>
                   </Col>

                   <Col>
                       <Button onClick={() => window.location.href = '/admin/analytics'} variant="dark">
                           Ziyaretçi Sayacı
                       </Button>
                   </Col>

                   <Col>
                       <Button onClick={() => window.location.href = '/admin/search-logs'} variant="info">
                           Arama İstatistikleri
                       </Button>
                   </Col>
               </Row>
           </Container>
        )

    }

}



const mapStateToProps = state => {
    return {
        user: state.user,
        login: state.login
    }
};


Home = connect(mapStateToProps)(Home);


export default Home;