import React, {Component} from 'react';
import axios from 'axios';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import './style.css';

import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';


class NewsEdit extends Component {

    constructor (props) {
        super(props);
        const {route} = this.props;
        this.newsId = route.match.params.id || false;

        console.log('ROUTEE', this.newsId);

        this.state = {
            editorState: EditorState.createEmpty(),
            form: {
                news_id: this.newsId,
                subject: '',
                content: '',
                slug: '',
                is_online: true,
                selectedFile: null,
                loadedFile: 0,
                fileUrl: 'default.png'
            },
            showSwal: false,
            swalTitle: '',
            swalMessage: '',
            swalType: 'error',
            swalCallback: null
        };

        this.baseUri = 'https://service.todab.org.tr';

        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleImageUploader = this.handleImageUploader.bind(this);
    }

    getNewsFromId(id) {
        const token = sessionStorage.getItem('x-user-token') || false;

        axios.get(`${this.baseUri}/get-admin-news`, {
            params: {news_id: id},
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => this.prepareToState(response.data.data),
            error => console.log('ERROR', error)
        );
    }

    prepareToState(data) {
        const blocksFromHtml = htmlToDraft(data.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState});
        this.setState(prevState => {
            let {form} = prevState;
            form.subject = data.subject;
            form.content = data.content;
            form.slug = data.slug;
            form.is_online = data.is_online === 1 ? true : false;
            form.fileUrl = data.main_image;
            return form;
        });
    }


    showSweetAlert(title, message, type, callback = null) {
        this.setState({
            showSwal: true,
            swalTitle: title,
            swalMessage: message,
            swalType: type,
            swalCallback: callback
        });
    }

    slugify(text) {
        return text.toString().toLocaleLowerCase()
                   .replace(/\s+/g, '-')           // Replace spaces with -
                   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                   .replace(/^-+/, '')             // Trim - from start of text
                   .replace(/-+$/, '');            // Trim - from end of text
    }

    handleEditorChange(value) {
        console.log('value: ', value);
        this.setState({editorState: value})
    }

    handleFormInputChange(e) {
        const {name, value} = e.target;

        if (name === 'is_online') {
            this.setState(prevVal => { let edited = prevVal.form; edited.is_online = !edited.is_online; return edited });
            return true;
        }

        if (name === 'subject') {
            let slug = this.slugify(value);
            this.setState(prevState => {let edited = prevState.form; edited.slug = slug; return edited });
        }

        this.setState(prevState => { let editedData = prevState.form; editedData[name] = value; return editedData});
    }

    handleImageUploader(e) {
        const file = e.target.files[0];

        this.setState(prevState => {
            let edited = prevState.form;
            edited.selectedFile = file;
            edited.loadedFile = 0;
            return {edited};
        });
    }


    handleSubmit() {
        const data = new FormData();
        if (this.state.form.selectedFile !== null) {
            data.append('file', this.state.form.selectedFile);
        }

        let content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState(prevState => {let x = prevState.form; x.content = content; return x;},

        () => {
            for (let [key, value] of Object.entries(this.state.form)) {
                data.append(key, value);
            }

            console.log('DATA', data, this.state.form.selectedFile);

            axios.post(`${this.baseUri}/admin-edit-news`, data, {
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem('x-user-token') || false}`
                }
            }).then(
                response => {
                    if (response.data.status) {
                        this.showSweetAlert('Tebrikler', 'Yeni Haber Başarı İle Güncellendi !', 'success', () => {
                            window.location.href = '/admin/news';
                        });
                    } else {
                        this.showSweetAlert('Hata', 'Lütfen Eksik Form Alanı Bırakmayınız.', 'error');
                    }
                },
                error => {
                    this.showSweetAlert('Hata', 'Lütfen Eksik Form Alanı Bırakmayınız.', 'error');
                }
            );
        });
    }

    renderEditor() {
        const {editorState} = this.state;
        return (
            <>
                <label>Haber İçeriği:</label>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={this.handleEditorChange}
                />

            </>
        )
    }


    componentDidMount() {
        this.getNewsFromId(this.newsId);
    }

    render() {
        return (
            <Container style={{marginTop:40}}>
                <SweetAlert
                    show={this.state.showSwal}
                    title={this.state.swalTitle}
                    text={this.state.swalMessage}
                    confirmButtonText={"Tamam"}
                    type={this.state.swalType}
                    onConfirm={() => this.setState({ showSwal: false }, () => {
                        if(this.state.swalCallback !== null) {
                            (this.state.swalCallback)();
                            //this.state.swallCallback();
                        }
                    })}
                />

                <Row>
                    <Col lg={12}>
                        <h3>Yeni Haber Oluştur</h3>
                        <br/>
                    </Col>

                    <Col lg={12}>
                        <Form>
                            <Form.Group controlId="subject">
                                <Form.Label>Haber Başlığı</Form.Label>
                                <Form.Control type="text" placeholder="Haber Başlığı" name={"subject"}
                                              value={this.state.form.subject}
                                                onChange={this.handleFormInputChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="slug">
                                <Form.Label>Slug Değeri</Form.Label>
                                <Form.Control plaintext readOnly type="text" placeholder="" value={this.state.form.slug} />
                            </Form.Group>
                        </Form>
                        <hr/>
                        <br/>
                    </Col>

                    <Col lg={12}>
                        <Form>
                            <Form.Group controlId="is_online">
                                <Form.Check type="checkbox" label="Yayında mı?"
                                            name={'is_online'} checked={this.state.form.is_online}
                                            onChange={this.handleFormInputChange}
                                />
                            </Form.Group>
                        </Form>
                        <hr/>
                        <br/>
                    </Col>

                    <Col lg={12}>
                        <Form>
                            <Form.Label>Haber Manşet Resimi:</Form.Label>

                            <div style={{width:300, height:250, border:'2px solid gray', margin:13,
                                background:`url(${this.baseUri}/newsImages/${this.state.fileUrl})`}}></div>


                            <label style={{color:'red'}}>Resimi Değiştirmek İstiyorsanız Aşağıdan Yeni Resim Seçebilirsiniz.</label>
                            <Form.Control type="file" placeholder="Resim" name={"image"}  onChange={this.handleImageUploader}/>
                        </Form>
                        <hr/>
                        <br/>
                    </Col>



                    <Col lg={12}>
                        {this.renderEditor()}
                        <br/>
                    </Col>


                    <Col lg={12}>
                        <Button variant={'success'} onClick={() => {this.handleSubmit()}}>Güncelle</Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}


export default NewsEdit;