import React, {Component} from 'react';
import {Container, Row, Col, Button, Form, Badge} from 'react-bootstrap';
import {getUserToken} from '../../../redux/actions/user';
import ReactTable from 'react-table';
import axios from 'axios';


class SiteContactRequestsAdmin extends  Component {

    constructor (props) {
        super(props);

        this.btnRef = React.createRef();
        this.baseUrl = 'https://service.todab.org.tr';

        this.state = {
            data: []
        };
    }


    getToken() {
        return sessionStorage.getItem('x-user-token') || false;
    }

    getExcel(additionalUrl = 'iletisim-istekleri') {
        let params = {excel: true};
        axios.get(`${this.baseUrl}/reports/${additionalUrl}`, {
            params: params,
            headers:
                {
                    'Authorization': `Bearer ${this.getToken()}`
                }
        })
             .then(
                 response => {
                     this.btnRef.current.href = response.data.file;
                     this.btnRef.current.innerText = response.data.name;
                     this.btnRef.current.click();
                 },
                 error => console.log('error', error)
             );
    }


    handleGetUserTokenButton() {
        let token = getUserToken();

        console.log('USER TOKEN', token);
    }


    getContactRequests() {
        const token = sessionStorage.getItem('x-user-token') || false;

        if (!token) return false;


        axios.get('https://service.todab.org.tr/admin-site-contact-requests', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
                if (typeof response.status !== 'undefined' && response.status) {
                    this.setState({data: response.data.data});
                } else {
                    // was error
                }
            },
            error => alert('HATA')
        );
    }



    componentDidMount() {
        this.getContactRequests();
    }

    renderTable() {
        const columns = [
            {
                Header: 'İsim Soyisim',
                id: 'fullname',
                accessor: 'fullname',
                width: 350
            },

            {
                Header: 'E Posta',
                id: 'email',
                accessor: 'email'
            },

            {
                Header: 'Konu',
                id: 'subject',
                accessor: 'subject'
            },

            {
                Header: 'Mesaj',
                accessor: 'message'
            },

            {
                Header: 'IP',
                accessor: 'client_ip'
            },

            {
                Header: 'Tarih',
                accessor: 'created_at'
            }
        ];

        const langProps = {
            previousText: 'Geri',
            nextText: 'İleri',
            loadingText: 'Yükleniyor',
            noDataText: 'Kayıt Bulunamadı',
            pageText: 'Sayfa',
            ofText: 'toplam',
            rowsText: 'satır'
        };

        return (
            <ReactTable data={this.state.data} columns={columns} {...langProps} minRows={5} style={{minHeight:350}}
            filterable={true} />
        )
    }

    render() {

        return (
            <Container fluid style={{marginTop:100}}>
                <a style={{display:'none'}} ref={this.btnRef}>Bas</a>
                <Row>
                    <Col lg={8}>
                        <h3>Site İletişim İstekleri <Badge variant="secondary">(Tümü)</Badge></h3>
                    </Col>
                    <Col lg={4}>
                        <Button variant={'success'} onClick={() => this.getExcel("site-contacts")}>Rapor Al</Button>
                    </Col>
                </Row>


                <br/>
                <Row>
                    <Col>

                        {this.renderTable()}

                    </Col>
                </Row>

            </Container>
        )

    }

}

export default SiteContactRequestsAdmin;