import {combineReducers} from 'redux';
import loginReducer from './login-reducers';
import userReducer from './user-reducers';

import { ADD_ARTICLE } from "../constants/action-types";

const initialState = {
    articles: []
};


function rootReducer(state = initialState, action) {
    if (action.type === ADD_ARTICLE) {
        return Object.assign({}, state, {
            articles: state.articles.concat(action.payload)
        });
    }
    return state;
}





export default combineReducers({root: rootReducer, login: loginReducer, user: userReducer });