import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import axios from 'axios';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input';

import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import AsyncSelect from 'react-select/async/dist/react-select.esm';


class NewStocks extends Component {

    constructor (props) {
        super(props);

        this.state = {
            // kategori
            categories: [],

            // tur
            types: [],

            // cesit
            kinds: [],


            selectedCategoryId: 0,
            selectedTypeId: 0,
            selectedKindId: 0,

            miktar:0,
            birim_fiyat: 0,

            telefon_goster:false,
            sms_mail_gonder: false,

            isSuccess: false,
        }

        this.getCategories();

        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeKind = this.handleChangeKind.bind(this);
        this.handleChangeMoneyInput = this.handleChangeMoneyInput.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    }



    getCategories() {

        const BASE_URL = 'https://portal.todab.org.tr';

        axios.get(BASE_URL+'/t-api/get-kategori-options')
            .then(response => response.data)
            .then(data => {

                if (data.status) {
                    let dataArray = [];

                    Object.keys(data.data).map(key => {
                        dataArray.push({id: key, name: data.data[key]});
                        return key;
                    });

                    console.log('DATA ARRAY', dataArray);

                    this.setState({
                        categories: dataArray
                    });
                }

            });
    }


    filterDynamic = (inputValue, type) => {
    	const copied = _.cloneDeep(this.state[type]);

	    let returnCat = [];

    	if (inputValue === '') {
    		returnCat = copied;
	    } else {
		    returnCat = copied.filter(ct =>
			    ct.name.toLocaleLowerCase("tr").indexOf(inputValue.toLocaleLowerCase("tr")) !== -1
		    );
	    }

	    let returnedData = [];
	    console.log('RETURN DYNO', returnCat);
	    let anies = returnCat.map(x => {
	    	returnedData.push({value: x.id,label:x.name});
	    });
	    return returnedData;
    }

    promiseFilterCategories = inputValue => {
    	let _this = this;
	    return new Promise(resolve => {
		    setTimeout(() => {
		    	console.log('THIS', _this);
			    resolve(_this.filterDynamic(inputValue, 'categories'));
		    }, 200)});
    }

	promiseFilterTypes = inputValue => {
		let _this = this;
		return new Promise(resolve => {
			setTimeout(() => {
				console.log('THIS', _this);
				resolve(_this.filterDynamic(inputValue, 'types'));
			}, 200)});
	}

	promiseFilterKinds = inputValue => {
		let _this = this;
		return new Promise(resolve => {
			setTimeout(() => {
				console.log('THIS', _this);
				resolve(_this.filterDynamic(inputValue, 'kinds'));
			}, 200)});
	}



	filterAllSelect = type => {
		let _this = this;
		return this.filterDynamic('', type);
	}


    getTypes() {
        const {selectedCategoryId} = this.state;
        const BASE_URL = 'https://portal.todab.org.tr';

        if (!selectedCategoryId) return false;

        axios.get(BASE_URL+'/t-api/get-tur-options/'+selectedCategoryId)
             .then(response => response.data)
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     console.log('DATA ARRAY', dataArray);

                     this.setState({
                         types: dataArray
                     });
                 }
             });
    }


    getKinds() {
        const {selectedTypeId} = this.state;
        const BASE_URL = 'https://portal.todab.org.tr';

        if (!selectedTypeId) return false;

        axios.get(BASE_URL+'/t-api/get-cesit-options/'+selectedTypeId)
             .then(response => response.data)
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     this.setState({
                         kinds: dataArray
                     });
                 }
             });
    }



    handleChangeCategory = e => {
    	console.log('CT E', e);
        //const {value}  = e.target;
        const {value}  = e;

        this.setState({
            selectedCategoryId: value,
            selectedTypeId: 0,
            selectedKindId: 0

        }, () => {
            this.getTypes();
        });
    };


    handleChangeType = e => {
        //const {value}  = e.target;
        const {value}  = e;

        this.setState({
            selectedTypeId: value,
            selectedKindId: 0
        }, () => {
            this.getKinds();
        });
    }


    handleChangeKind = e => {
        const {value}  = e;
        //const {value}  = e.target;
        this.setState({
            selectedKindId: value
        });
    }

    handleChangeMoneyInput(event, maskedvalue, floatvalue) {
        const {name} = event.target;
        console.log('FLOAT VAL', floatvalue, 'Normal Value', maskedvalue);
        this.setState({[name]: maskedvalue});
    }

    handleChangeCheckbox(e) {
        const {name, value}  = e.target;
        console.log('NAME', name, 'VALUE', value);
        this.setState(prevState => { let val = prevState[name];  prevState[name] = !prevState[name]; return {prevState} });
    }


    handleSuccessStore(data) {
        this.setState({
            isSuccess: true
        })
    }

    handleErrorStatus(){
        this.setState({
            isSuccess: false
        })
    }

    createStock() {
        const formatMoney = x => {
            x = x.toString() || '';
            return (x || '').replace(/\,/g, '.') || '';
        }



        let formData = {
            miktar :  formatMoney(this.state.miktar),
            kategori: this.state.selectedCategoryId,
            tur: this.state.selectedTypeId,
            cesit: this.state.selectedKindId,
            birim_fiyat: this.state.birim_fiyat,
            telefon_goster: this.state.telefon_goster,
            sms_mail_gonder: this.state.sms_mail_gonder
        }

        if ( (formData.kategori === 0 || formData.cesit === 0 || formData.tur === 0) || ( !formData.telefon_goster && !formData.sms_mail_gonder) ) {
            alert('Lütfen Formdaki Alanları Doldurun!');
        } else {
            const BASE_URL = 'https://service.todab.org.tr';
            const token = sessionStorage.getItem('x-user-token') || false;

            axios.post(BASE_URL+'/create-user-stock', formData,
                {headers: {'Authorization': `Bearer ${token}`}}
            ).then(
                response => this.handleSuccessStore(response.data),
                error => this.handleErrorStatus(error)
            );
        }



    }

    render () {
        return (
            <Container style={{marginTop:30}}>
                <Row>
                    <Col>
                        <h4 align="center">Yeni Stok Kaydı</h4>
                    </Col>
                </Row>

                <Row>

                    <Col>

                        <SweetAlert
                            show={this.state.isSuccess}
                            title="İşlem Başarılı"
                            text="Tebrikler, Stok Kaydı Başarı İle eklendi"
                            confirmButtonText={"Tamam"}
                            onConfirm={() => this.setState({ isSuccess: false }, () => window.location.href='/backend/stocks' )}
                        />

                        <Form>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Kategori</Form.Label>
	                            <AsyncSelect cacheOptions
	                                         loadingMessage={() => "Yükleniyor..."}
	                                         noOptionsMessage={() => "Böyle Bir Kayıt Bulunamadı !"}
	                                         placeholder={"Seçiniz"}
	                                         defaultOptions={this.filterAllSelect('categories')}
	                                         onChange={this.handleChangeCategory}
	                                         loadOptions={this.promiseFilterCategories} />

	                            {/* <Form.Control as="select"  onChange={this.handleChangeCategory} value={this.state.selectedCategoryId}>
                                    <option value={0}> Lütfen Seçiniz </option>

                                    {
                                        _.map(this.state.categories, (category) => {
                                            return <option value={category.id}> {category.name} </option>
                                        })
                                    }
                                </Form.Control> */}
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Tür</Form.Label>
	                            <AsyncSelect cacheOptions
	                                         loadingMessage={() => "Yükleniyor..."}
	                                         noOptionsMessage={() => "Böyle Bir Kayıt Bulunamadı !"}
	                                         placeholder={"Seçiniz"}
	                                         defaultOptions={this.filterAllSelect('types')}
	                                         onChange={this.handleChangeType}
	                                         loadOptions={this.promiseFilterTypes} />

	                            {/*<Form.Control as="select"  onChange={this.handleChangeType}  value={this.state.selectedTypeId}>
                                    <option value={0}> Lütfen Seçiniz </option>
                                    {
                                        _.map(this.state.types, (type) => {
                                            return <option value={type.id}> {type.name} </option>
                                        })
                                    }
                                </Form.Control> */}
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect2">
                                <Form.Label>Çeşit</Form.Label>
	                            <AsyncSelect cacheOptions
	                                         loadingMessage={() => "Yükleniyor..."}
	                                         noOptionsMessage={() => "Böyle Bir Kayıt Bulunamadı !"}
	                                         placeholder={"Seçiniz"}
	                                         defaultOptions={this.filterAllSelect('kinds')}
	                                         onChange={this.handleChangeKind}
	                                         loadOptions={this.promiseFilterKinds} />

	                            { /*<Form.Control as="select" value={this.state.selectedKindId} onChange={this.handleChangeKind}>
                                    <option value={0}> Lütfen Seçiniz </option>
                                    {
                                        _.map(this.state.kinds, (kind) => {
                                            return <option value={kind.id}> {kind.name} </option>
                                        })
                                    }
                                </Form.Control> */ }
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Tohum Miktarı (isteğe bağlı)</Form.Label>
                                <CurrencyInput
                                    name={'miktar'}
                                    value={this.state.miktar}
                                    decimalSeparator="," thousandSeparator="."
                                    onChangeEvent={this.handleChangeMoneyInput}
                                    className={'form-control'}
                                />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Birim Satış Fiyatı (isteğe bağlı)</Form.Label>
                                <CurrencyInput
                                    name={'birim_fiyat'}
                                    value={this.state.birim_fiyat}
                                    decimalSeparator="," thousandSeparator="."
                                    onChangeEvent={this.handleChangeMoneyInput}
                                    className={'form-control'}
                                />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.cb1">
                                <Form.Label>İletişim Seçenekleri</Form.Label>
                                <Form.Check type="checkbox" name='telefon_goster' value={1}
                                            checked={this.state.telefon_goster}
                                            onChange={this.handleChangeCheckbox} label="Cep telefonu numaram arama listesinde görünsün" />

                            </Form.Group>

                            <Form.Group controlId="exampleForm.cb2">
                                <Form.Check type="checkbox" name='sms_mail_gonder' value={1}
                                            checked={this.state.sms_mail_gonder}
                                            onChange={this.handleChangeCheckbox} label="Alıcılar bana SMS ve Mail ile ulaşsın" />
                            </Form.Group>

                            <Button variant="success" onClick={() => this.createStock()}>
                                Kaydet
                            </Button>
                        </Form>


                    </Col>
                </Row>
            </Container>
        )
    }

}

export default NewStocks;
