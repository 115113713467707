import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Button, Container, Row, Col, Card, Alert, Spinner} from 'react-bootstrap';
import { postLoginRequest, startLoginRequest } from './../../redux/actions/login';


class Login extends Component {

    constructor (props) {
        super(props);

        this.state = {
            formData: {
                username: '',
                password: ''
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange (event) {
        const {name, value} = event.target;
        this.setState(prevState => {
            let formData = Object.assign({}, prevState.formData);
            formData[name] = value;
            return {formData};
        })
    }


    showError() {
        return (
            <>

                { this.props.login.loginError &&
                    <Alert key={ 'alert-x' } variant={ 'danger' }>
                        Giriş Sırasında Bir Problem Meydana Geldi, Lütfen Tekrar Deneyiniz
                    </Alert>
                }
            </>
        )
    }

    render () {
        return (
            <Container fluid style={{minHeight:'1080px', width:'100%', backgroundColor:'rgb(100, 208, 154)'}}>
                <Row>
                    <Col></Col>
                    <Col>

                        <Card style={{marginTop:120}}>
                            {this.showError()}
                            <h4 align="center" style={{marginTop:40}}>Giriş Yap </h4>
                            <Card.Body>
                                <Form onSubmit={(event) => event.preventDefault()}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Kullanıcı Adı</Form.Label>
                                        <Form.Control name={'username'} onChange={this.handleInputChange} type="text" placeholder="Kullanıcı Adı" />
                                        <Form.Text className="text-muted">
                                            Tüzel Bayiler İçin Kullanıcı Adı : Vergi Numarası'dır.

                                            Gerçek Bayiler İçin Kullanıcı Adı : TC Kimlik Numarası'dır.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Şifre</Form.Label>
                                        <Form.Control name={"password"} onChange={this.handleInputChange}  type="password" placeholder="Şifre" />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicChecbox">
                                        <Form.Check type="checkbox" label="Beni hatırla" />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" block onClick={() => this.props.loginAction(this.state.formData)}>

                                        {
                                            this.props.login.isFetching ?
                                                <Spinner animation="border" />
                                            :
                                                'Giriş Yap'
                                        }
                                    </Button>

                                </Form>
                            </Card.Body>
                        </Card>



                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        login: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginAction: (formData) => {
            dispatch(startLoginRequest());
            dispatch(postLoginRequest(formData))
        }
    }
}

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login;