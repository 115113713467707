import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {Navbar, NavbarBrand, Nav, Form, FormControl, Button} from 'react-bootstrap';
import { checkIsAdmin } from '../../redux/actions/login';

class Header extends Component {


    renderUserHeader() {
        if (this.props.login.isAuthenticated && checkIsAdmin(this.props.login) === false) {
            return (
                <>
                    <Navbar bg="primary" variant="dark">
                        <Navbar.Brand href="/backend/home">Tohum Nerede</Navbar.Brand>
                        <Nav className="mr-auto">
                            <Nav.Link href="/backend/home">Ana Sayfa</Nav.Link>
                            <Nav.Link href={'/backend/stocks'}>Stok Listesi</Nav.Link>
                            <Nav.Link href={'/backend/new-stock'}>Yeni Kayıt</Nav.Link>
                            <Nav.Link href={'/backend/contact-requests'}>İletişim İstekleri</Nav.Link>
                        </Nav>
                    </Navbar>
                </>
            )
        }

        return (<></>);
    }

    renderAdminHeader() {
        let isAdmin = checkIsAdmin(this.props.login);

        if (isAdmin) {
            return (
                <>
                    <Navbar bg="primary" variant="dark">
                        <Navbar.Brand href="/admin/home">Tohum Nerede</Navbar.Brand>
                        <Nav className="mr-auto">
                            <Nav.Link href="/admin/home">Ana Sayfa</Nav.Link>
                            <Nav.Link href={'/admin/data-manager'}>Kategori - Tür Yonetimi</Nav.Link>
                            <Nav.Link href={'/admin/stock-lists'}>Stok Listesi</Nav.Link>
                            <Nav.Link href={'/admin/site-contacts'}>Site İletişim İstekleri</Nav.Link>
                            <Nav.Link href={'/admin/contact-requests'}>İletişim İstekleri</Nav.Link>
                            <Nav.Link href={'/admin/search-logs'}>Arama İstatistikleri</Nav.Link>
                            <Nav.Link href={'/admin/analytics'}>Ziyaretçi Sayacı</Nav.Link>
                            <Nav.Link href={'/admin/news'}>Haber Yönetimi</Nav.Link>
                            <Nav.Link href={'/admin/announcement'}>Duyuru Yönetimi</Nav.Link>
                            <Nav.Link href={'/admin/sliders'}>Slider Yönetimi</Nav.Link>
                            <Nav.Link href={'/admin/marquee'}>Marquee Yönetimi</Nav.Link>
                        </Nav>
                    </Navbar>
                </>
            )
        }

        return (<></>);
    }

    render () {
        return (
                <>
                    {this.renderAdminHeader()}
                    {this.renderUserHeader()}
                </>
            )
    }

}


const mapStateToProps = state => {
    return {
        user: state.user,
        login: state.login,
    }
};

Header = connect(mapStateToProps)(Header);

export default Header;