import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Button, Modal} from 'react-bootstrap';
import {
    deleteStockRequest,
    getStocksRequest,
    resetAbortStateStocksData,
    updateStockRequest
} from '../../redux/actions/user';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input';
import NumberFormat from 'react-number-format';
import SimpleMaskMoney from 'simple-mask-money';
import SweetAlert from 'sweetalert-react';
import ReactTable from 'react-table';
import 'sweetalert/dist/sweetalert.css';


class Stocks extends Component {


    constructor (props) {
        super(props);
        this.state = {
            showEditModal: false,
            sweetAlertShow: false,
            editedRecordId: false,

            removePopupShow: false,
            removedRecordId: false,
            removeSuccessPopup: false,

            editedData: {
                miktar : '0.00',
                birimFiyat: '0,00',
                sms_mail_gonder: 0,
                telefon_goster: 0
            }
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleChangeMoneyInput = this.handleChangeMoneyInput.bind(this);
        this.handleChangeFormatInput = this.handleChangeFormatInput.bind(this);
        this.handleMaskMoneyInput = this.handleMaskMoneyInput.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        this.updateRecord = this.updateRecord.bind(this);

        this.props.getStocks();
    }


    replaceAtIndex(str, index, replacement) {
        return str.substr(0, index) + replacement+ str.substr(index + replacement.length);
    }

    buildName = (rec) => {
        return `${rec.kategori_name.category_name} - ${rec.tur_name.category_name} - ${rec.cesit_name.category_name}`;
    }

    handleClose() {
        this.setState({
            showEditModal: false
        });

        this.props.resetAbortUpdateStateStocks();
    }

    handleOpen(recordId) {
        console.log('RECORD ID', recordId);
        const record = this.getStockDataById(recordId);

        this.setState({
            showEditModal: true,
            editedRecordId: recordId,
            editedData: {
                miktar: this.replaceAtIndex(record.miktar, record.miktar.lastIndexOf('.'), ','),
                birimFiyat: record.birim_fiyat,
                sms_mail_gonder: Boolean(record.sms_mail_gonder),
                telefon_goster: Boolean(record.telefon_goster)
            }
        });
    }

    getStockDataById(id = false) {
        if (!id) id = this.state.editedRecordId;

        const c = {...this.props.user.stocks.find((rec, key) => rec.id === id)} || [];

        return c;
    }

    handleChangeMoneyInput(event, maskedvalue, floatvalue) {
        const {name} = event.target;
        console.log('FLOAT VAL', floatvalue, 'Normal Value', maskedvalue);
        this.setState(prevState => { let editData = prevState.editedData;  editData[name] = maskedvalue; return {editData}});
    }

    handleChangeFormatInput(values) {
        const {formattedValue, value} = values;
        console.log('FORMATTED VALIE', formattedValue, 'NORMAL VALUE', value);
        this.setState(prevState => { let editData = prevState.editedData;  editData['miktar'] = formattedValue; return {editData}});
    }

    handleMaskMoneyInput(event) {
        const {name, value} = event.target;
        console.log('NAME', name, 'VALUE', value);
    }

    // checkbox
    handleChangeCheckBox(event) {
        const {name, value} = event.target;
        console.log('X', name, value);
        this.setState(prevState => { let editData = prevState.editedData;  editData[name] = !editData[name]; return {editData}});
    }


    // guncellenen kaydin datasini update eder.
    mutateChangedRecord(stockId, newData) {
        this.props.user.stocks = this.props.user.stocks.map((stock) => {
            if (stock.id === stockId) {
                stock = newData;
            }
            return stock;
        })
    }

    updateRecord() {

        if (!this.state.editedData.sms_mail_gonder && !this.state.editedData.telefon_goster) {
            alert('Lütfen İletişim Seçeneklerinden En Az Birini seçiniz.');
        } else {

            const formatMoney = x => {
                x = x.toString() || '';
                return (x || '').replace(/\,/g, '.') || '';
            }

            let formData = {
                stock_id: this.state.editedRecordId,
                miktar: formatMoney(this.state.editedData.miktar),
                birim_fiyat: this.state.editedData.birimFiyat,
                sms_mail_gonder: this.state.editedData.sms_mail_gonder,
                telefon_goster: this.state.editedData.telefon_goster
            };


            this.props.updateStocks(this.state.editedRecordId, formData)
                .then((state) => {
                    if (state.payload.status) {
                        // islem basarili
                        this.setState(
                            {
                                sweetAlertShow:true,
                                showEditModal: false,
                            },

                            // callback
                            () => {
                                this.mutateChangedRecord(state.payload.data.id, state.payload.data)
                            }
                        );

                    }
                })
            console.log('FORM DATA', formData);
        }
    }

    renderEditModal() {

        if (this.state.showEditModal) {
            const stockData = this.getStockDataById();

            return (
                <Modal size={'lg'} show={this.state.showEditModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Stok Kaydını Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.props.user.updateError && <><b className={'text-danger'}>Güncelleme İşleminde Bir Hata İle Karşılaşıldı</b><br/><br/><br/></>}

                        <p><b>Kategori:</b> <b className={'text-danger'}>{this.buildName(stockData)}</b></p>
                        <p><b>Miktar:</b> <b className={'text-danger'}>
                            <CurrencyInput
                                name={'miktar'}
                                value={this.state.editedData.miktar}
                                decimalSeparator="," thousandSeparator="."
                                onChangeEvent={this.handleChangeMoneyInput}
                                className={'form-control'}
                            />

                           </b></p>
                        <p><b>Birim Fiyat (TL Cinsinden) :</b> <b className={'text-danger'}>
                            <CurrencyInput
                                name={'birimFiyat'}
                                value={this.state.editedData.birimFiyat}
                                decimalSeparator="," thousandSeparator="."
                                onChangeEvent={this.handleChangeMoneyInput}
                                className={'form-control'}
                            />
                        </b></p>

                        <hr/>

                        <div className={'form-group'}>
                            <b>İletişim Seçenekleri:</b>
                            <br/>

                            <label><input type={'checkbox'} name={'telefon_goster'} value={1} checked={this.state.editedData.telefon_goster} onChange={this.handleChangeCheckBox}/> Cep telefonu numaram arama listesinde görünsün </label>
                            <br/>
                            <label><input type={'checkbox'} name={'sms_mail_gonder'} value={1} checked={this.state.editedData.sms_mail_gonder} onChange={this.handleChangeCheckBox} /> Alıcılar bana SMS ve Mail ile ulaşsın </label>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Vazgeç
                        </Button>
                        <Button variant="primary"  disabled={this.props.user.isFetching} onClick={this.updateRecord}>
                            Güncelle
                        </Button>
                    </Modal.Footer>

                </Modal>

            )
        }

    }



    removeRecordHandler(stockId) {
        this.setState({
            removedRecordId: stockId,
            removePopupShow: true
        });
    }

    removeRecord(stockId) {
        this.props.deleteStock({stock_id: stockId})
            .then((state) => {
                if (state.payload.status) {
                    // silindi !

                    this.setState({
                        removePopupShow:false,
                        removeSuccessPopup: true,
                    },
                        // callback
                        () => {

                            this.props.user.stocks = this.props.user.stocks.filter((record) => {
                                return record.id !== this.state.removedRecordId;
                            });
                    });


                }
            });
    }

    renderTable() {

        const columns = [
            {
                Header: 'Kategori',
                accessor: d => this.buildName(d),
                id: 'colKategori',
                width: 350,
                style: {textAlign:'center'}
            },

            {
                Header: 'Miktar',
                accessor: d => `${d.miktar} ${d.cesit_name.satis_tipi_text}`,
                style: {textAlign:'center'},
                id: 'colMiktar'

            },

            {
                Header: 'Birim Fiyat',
                accessor: d => `${d.birim_fiyat} ₺`,
                id: 'colBirimFiyat',
                style: {textAlign:'center'}

            },

            {
                Header: 'Numaramı Görsünler',
                accessor: d => d.telefon_goster === 0 ? (<i className="fa fa-close"></i>) : (<i className="fa fa-check"></i>),
                id: 'colShowNumber',
                style: {textAlign:'center'}

            },

            {
                Header: 'Sms & Mail Göndersinler',
                accessor: d => d.sms_mail_gonder === 0 ? (<i className="fa fa-close"></i>) : (<i className="fa fa-check"></i>),
                id: 'colShowSmsMail',
                style: {textAlign:'center'}

            },

            {
                Header: 'İşlemler',
                style: {textAlign:'center'},
                accessor: d => {
                    return (<>
                        <Button variant={'primary'} onClick={ () => this.handleOpen(d.id)}> <i className="fa fa-pencil"></i> Düzenle</Button> &nbsp;
                        <Button variant={'danger'} onClick={ () => this.removeRecordHandler(d.id)}><i className="fa fa-trash"></i> Sil</Button>
                    </>)
                },
                id: 'colActions'
            }
        ];

        const langProps = {
            previousText: 'Geri',
            nextText: 'İleri',
            loadingText: 'Yükleniyor',
            noDataText: 'Kayıt Bulunamadı',
            pageText: 'Sayfa',
            ofText: 'toplam',
            rowsText: 'satır'
        };

        return (
            <ReactTable
                style={{height:350}} {...langProps} minRows={5}
                data={this.props.user.stocks}
                columns={columns}
                />
        );
    }

    render () {
        return (
            <Container fluid style={{marginTop:30}}>
                <Row>
                    <Col>
                        <Button variant={'success'} onClick={() => window.location.href='/backend/new-stock'}><i className={'fa fa-plus'}></i> Yeni Stok Kaydı Ekle </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <SweetAlert
                            show={this.state.sweetAlertShow}
                            title="İşlem Başarılı"
                            text="Tebrikler, Kayıt Başarı İle güncellendi"
                            confirmButtonText={"Tamam"}
                            onConfirm={() => this.setState({ sweetAlertShow: false })}
                        />

                        <SweetAlert
                            show={this.state.removePopupShow}
                            title="Uyarı"
                            text="Bu Stok Kaydını Silmek İstediğinize Emin misiniz?"
                            showCancelButton
                            confirmButtonText={'Sil'}
                            cancelButtonText={'Vazgeç'}
                            onConfirm={() => this.setState({ removePopupShow: false }, () => this.removeRecord(this.state.removedRecordId))}
                            onCancel={() => this.setState({removePopupShow:false})}
                        />

                        <SweetAlert
                            show={this.state.removeSuccessPopup}
                            title="Bilgilendirme"
                            text="Stok Kaydı Başarı İle Silindi !"
                            confirmButtonText={'Tamam'}
                            onConfirm={() => this.setState({ removeSuccessPopup: false })}
                        />


                        <h3 align="center">Stok Listesi</h3>

                        {this.renderEditModal()}
                        {this.renderTable()}
                    </Col>
                </Row>
            </Container>
        );
    }

}



const mapStateToProps = state => {
    return {
        user: state.user,
        login: state.login
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getStocks: () => dispatch(getStocksRequest()),
        updateStocks: (stockId, formData) => dispatch(updateStockRequest(stockId, formData)),
        resetAbortUpdateStateStocks: () => dispatch(resetAbortStateStocksData()),
        deleteStock: (stock_id) => dispatch(deleteStockRequest(stock_id))
    }
}

Stocks = connect(mapStateToProps, mapDispatchToProps)(Stocks);

export default Stocks;