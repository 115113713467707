import React, {Component} from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import axios from 'axios';
import _ from 'lodash';
import SweetAlert from 'sweetalert-react';


class DataManager extends Component {

    constructor (props) {
        super(props);

        this.state = {
            categories: [],
            types: [],
            kinds: [],
            selectedCategoryId: 0,
            selectedTypeId: 0,
            selectedKindId: 0,

            createForm: {
                categoryName: '',
                typeName: '',
                kindName: '',
                kindType: 1
            },

            editForm: {
                categoryName: '',
                typeName: '',
                kindName: ''
            },

            showModal: false,
            modalTitle: '',
            modalText: ''
        };


        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.createInputHandler = this.createInputHandler.bind(this);
        this.createNewData = this.createNewData.bind(this);
        this.checkCreateInputDisabledStatus = this.checkCreateInputDisabledStatus.bind(this);
        this.checkCreateButtonDisableStatus = this.checkCreateButtonDisableStatus.bind(this);
        this.editInputHandler = this.editInputHandler.bind(this);
    }

    getCategories() {

        const BASE_URL = 'https://portal.todab.org.tr';

        axios.get(BASE_URL+'/t-api/get-kategori-options')
             .then(response => response.data)
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     this.setState({
                         categories: dataArray
                     });
                 }

             });
    }

    getTypes() {
        const {selectedCategoryId} = this.state;
        const BASE_URL = 'https://portal.todab.org.tr';

        if (!selectedCategoryId) return false;

        return axios.get(BASE_URL+'/t-api/get-tur-options/'+selectedCategoryId)
                    .then(response => response.data)
                    .then(data => {

                        if (data.status) {
                            let dataArray = [];

                            Object.keys(data.data).map(key => {
                                dataArray.push({id: key, name: data.data[key]});
                                return key;
                            });

                            console.log('DATA ARRAY', dataArray);

                            this.setState({
                                types: dataArray
                            });
                        }
                    });
    }

    getKinds() {
        const {selectedTypeId} = this.state;
        const BASE_URL = 'https://portal.todab.org.tr';

        if (!selectedTypeId) return false;

        axios.get(BASE_URL+'/t-api/get-cesit-options/'+selectedTypeId)
             .then(response => response.data)
             .then(data => {

                 if (data.status) {
                     let dataArray = [];

                     Object.keys(data.data).map(key => {
                         dataArray.push({id: key, name: data.data[key]});
                         return key;
                     });

                     this.setState({
                         kinds: dataArray
                     });
                 }
             });
    }

    handleSelectChange(e){
        const {name, value} = e.target;

        const updateEditState = (_name, _value) => {
            console.log('_NAME', _name, '_VALUE', _value);
            let _newValue = this.getSelectedValueFromData(_name, _value);
            this.setState(prevState => {let editedData = prevState.editForm; editedData[`${name}Name`] = _newValue; return {editedData}});
        }

        switch(name) {
            case 'category':
                this.setState({selectedCategoryId: value, selectedTypeId: 0, selectedKindId: 0}, () => {
                    this.getTypes();
                });
                break;

            case 'type':
                this.setState({selectedTypeId: value, selectedKindId: 0}, () => {
                    this.getKinds();
                });
                break;

            case 'kind':
                this.setState({selectedKindId: value});
                break;

            default:
                break;
        }


        const nameToState = {
            category: 'categories',
            type: 'types',
            kind: 'kinds'
        };

        updateEditState(nameToState[name], value);
    }

    getSelectedValueFromData(type, id) {

        return (this.state[type].find((x) => {
            return parseInt(x.id || 0) === parseInt(id || 0);
        }) || []).name;
    }

    createInputHandler(e) {
        const {name, value} = e.target;
        this.setState(prevState => { let crForm = prevState.createForm; crForm[name] = value; return {crForm}});
    }

    createNewData(type, parentId = false) {

        const types = {
            category:1,
            type: 2,
            kind: 3
        };


        let params = {
            name: this.state.createForm[`${type}Name`],
            parentId: parentId || undefined,
            type: types[type],
            placeType: (type === 'kind') ? this.state.createForm.kindType : undefined
        };

        const token = sessionStorage.getItem('x-user-token') || false;

        axios.post('https://service.todab.org.tr/create-seed-data-type', params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(
                response => {
                    console.log('RESPONSE', response.data);
                    this.setState({
                        showModal: true,
                        modalTitle: 'Bilgilendirme',
                        modalText: 'Veri Başarı İle Eklendi.'
                    }, () => {
                        this.resetFormAfterCreate(type);
                        this.updateStateAfterCreate(type, response.data.object)
                    })
                },
                error => {
                    console.log('ERROR CRUD', error);
                }
            );
    }

    resetFormAfterCreate(type) {
        this.setState(prevState => {let editedData = prevState.createForm; editedData[`${type}Name`] = ''; return {editedData}});
    }

    checkCreateInputDisabledStatus(name) {

        switch(name) {
            case 'category':
                return false;

            case 'type':
                return parseInt(this.state.selectedCategoryId || 0) === 0;

            case 'kind':
                return parseInt(this.state.selectedTypeId || 0) === 0;

            default:
                return true;
        }
    }

    checkCreateButtonDisableStatus(name) {
        let selectStatus = this.checkCreateInputDisabledStatus(name);
        let elName = `${name}Name`;

        const stateValue = this.state.createForm[elName];
        return ( (stateValue === '' || stateValue.trim().length < 1 ) || selectStatus);
    }

    editInputHandler (e) {
        const {name, value} = e.target;

        this.setState(prevState => {
            let editedData = prevState.editForm;
            editedData[name] = value;
            return {editedData};
        });
    }

    editData(type) {
        const typeToState = {
            category : this.state.selectedCategoryId,
            type: this.state.selectedTypeId,
            kind: this.state.selectedKindId
        };

        const params = {
            recordId: typeToState[type],
            name: this.state.editForm[`${type}Name`]
        };

        const token = sessionStorage.getItem('x-user-token') || false;

        axios.post('https://service.todab.org.tr/edit-seed-data-type', params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
             .then(
                 response => {
                     console.log('RESPONSE', response.data);
                     this.setState({
                         showModal: true,
                         modalTitle: 'Bilgilendirme',
                         modalText: 'Veri Başarı İle Güncellendi.'
                     }, () => {
                         this.updateStateAfterEdit(type, params.name);
                     })
                 },
                 error => {
                     console.log('ERROR CRUD', error);
                 }
             );
    }

    updateStateAfterEdit(type, newValue) {
        const typeToState = {
            category : this.state.selectedCategoryId,
            type: this.state.selectedTypeId,
            kind: this.state.selectedKindId
        };

        const typeToDataArray = {
            category : 'categories',
            type: 'types',
            kind: 'kinds'
        };

        let dataRepository = typeToDataArray[type];
        let recordId = typeToState[type];

        let oldState = this.state[dataRepository];
        let newRepository = oldState.map(record => {
            if (record.id === recordId) {
                record.name = newValue;
            }
            return record;
        });

        this.setState({
            [dataRepository]: newRepository
        });

    }

    updateStateAfterCreate(type, object) {
        const typeToDataArray = {
            category : 'categories',
            type: 'types',
            kind: 'kinds'
        };

        let oldRepository = this.state[typeToDataArray[type]];
        oldRepository.push({
            id: object.id,
            name: object.category_name
        });
    }


    getSelectedDataNameFromState(type) {
        const typeToState = {
            category: {store: 'categories', selected: 'selectedCategoryId'},
            type: { store: 'types', selected: 'selectedTypeId'},
            kind: { store: 'kinds', selected: 'selectedKindId'}
        };

        const typeState = typeToState[type];
        const selectedId = this.state[typeState.selected];
        const store = typeState.store;

        let value = this.getSelectedValueFromData(store, selectedId);

        return value;
    }

    componentDidMount() {
        this.getCategories();
    }

    render() {

        return (
            <Container style={{marginTop:60}}>
                <SweetAlert
                    show={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    onConfirm={() => this.setState({ showModal: false })}
                />


                <Row>
                    <Col>
                        <h2>Kategori - Tür - Çeşit Yönetimi </h2>
                    </Col>
                </Row>

                <Row>

                    <Col lg={4}>
                        <Form>
                            <Form.Group controlId="categorySelect">
                                <Form.Label>Kategori Seçin</Form.Label>
                                <Form.Control as="select" name={'category'} onChange={this.handleSelectChange} value={this.state.selectedCategoryId}>
                                    <option value={0} selected>Lütfen Kategori Seçiniz...</option>
                                    {
                                        _.map(this.state.categories, (category) => {
                                            return (<option value={category.id}>{category.name}</option>);
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>

                                {
                                    (this.state.selectedCategoryId !== 0) &&
                                    <Row>
                                        <Col lg={9}>
                                            <Form.Label>Kategoriyi Düzenle:</Form.Label>
                                            <Form.Control type="text" value={this.state.editForm.categoryName} name={'categoryName'} onChange={this.editInputHandler}/>
                                        </Col>
                                        <Col lg={3}>
                                            <br/>
                                            <Button onClick={() => this.editData('category')} style={{marginTop:10}} variant={'success'} size={'sm'}><i className={'fa fa-save'}></i></Button>
                                        </Col>
                                    </Row>
                                }
                        </Form>
                    </Col>

                    <Col lg={4}>
                        <Form>
                            <Form.Group controlId="typeSelect">
                                <Form.Label>Tür Seçin:</Form.Label>
                                <Form.Control as="select" name={'type'} onChange={this.handleSelectChange} value={this.state.selectedTypeId} >
                                    <option value={0} selected>Lütfen Tür Seçiniz...</option>
                                    {
                                        _.map(this.state.types, (type) => {
                                            return (<option value={type.id}>{type.name}</option>);
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>

                            {
                                (this.state.selectedTypeId !== 0) &&
                                <Form.Group controlId="typeEdit">
                                    <Row>
                                        <Col lg={9}>
                                            <Form.Label>Türü Düzenle:</Form.Label>
                                            <Form.Control type="text" value={this.state.editForm.typeName} name={'typeName'} onChange={this.editInputHandler} />
                                        </Col>
                                        <Col lg={3}>
                                            <br/>
                                            <Button onClick={() => this.editData('type')} style={{marginTop:10}} variant={'success'} size={'sm'}><i className={'fa fa-save'}></i></Button>
                                        </Col>
                                    </Row>

                                </Form.Group>
                            }
                        </Form>
                    </Col>

                    <Col lg={4}>
                        <Form>
                            <Form.Group controlId="kindSelect">
                                <Form.Label>Çeşit Seçin</Form.Label>
                                <Form.Control as="select" name={'kind'} onChange={this.handleSelectChange} value={this.state.selectedKindId}>
                                    <option value={0} selected>Lütfen Çeşit Seçiniz...</option>
                                    {
                                        _.map(this.state.kinds, (kind) => {
                                            return (<option value={kind.id}>{kind.name}</option>);
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>

                            {
                                (this.state.selectedKindId !== 0) &&
                                <Row>
                                    <Col lg={9}>
                                        <Form.Label>Çeşiti Düzenle:</Form.Label>
                                        <Form.Control type="text"  value={this.state.editForm.kindName} name={'kindName'} onChange={this.editInputHandler}/>
                                    </Col>
                                    <Col lg={3}>
                                        <br/>
                                        <Button onClick={() => this.editData('kind')} style={{marginTop:10}} variant={'success'} size={'sm'}><i className={'fa fa-save'}></i></Button>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Col>

                </Row>



                <br/>
                <hr/>
                <br/>
                <Row>
                    <Col>
                        <h3>Yeni Kategori - Tür - Çeşit Ekle</h3>
                    </Col>
                </Row>

                <br/>


                <Row>
                    <Col lg={12}>
                        <Form>
                            <Row>
                                <Col lg={11}>
                                    <Form.Group controlId="categoryAdd">
                                        <Form.Label><b>Kategori Adı:</b></Form.Label>
                                        <Form.Control type="text" name='categoryName' value={this.state.createForm.categoryName} onChange={this.createInputHandler} />
                                    </Form.Group>
                                </Col>

                                <Col lg={1}>
                                    <br/>
                                    <Button  disabled={this.checkCreateButtonDisableStatus('category')} onClick={() => this.createNewData('category')} style={{marginTop:10}} variant={'primary'} size={'sm'}><i className={'fa fa-save'}></i></Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>


                    <Col lg={12}>
                        <Form>
                            <Row>
                                <Col lg={11}>
                                    <Form.Group controlId="typeAdd">
                                        <Form.Label><b>Tür Adı:</b> {
                                            this.checkCreateInputDisabledStatus('type') ?
                                                <span>Tür Eklemek için önce yukarıdan bir "Kategori" seçiniz. </span>
                                                :
                                                <span className={'text-danger'}>{this.getSelectedDataNameFromState('category')} Kategorisine Eklenecektir.</span>
                                        } </Form.Label>
                                        <Form.Control disabled={this.checkCreateInputDisabledStatus('type')} type="text" name='typeName' value={this.state.createForm.typeName} onChange={this.createInputHandler} />
                                    </Form.Group>
                                </Col>

                                <Col lg={1}>
                                    <br/>
                                    <Button  disabled={this.checkCreateButtonDisableStatus('type')}  onClick={() => this.createNewData('type', this.state.selectedCategoryId)} style={{marginTop:10}} variant={'primary'} size={'sm'}><i className={'fa fa-save'}></i></Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>


                    <Col lg={12}>
                        <Form>
                            <Row>
                                <Col lg={8}>
                                    <Form.Group controlId="kindAdd">
                                        <Form.Label><b>Çeşit Adı:</b> {
                                            this.checkCreateInputDisabledStatus('kind') ?
                                            <span>Çeşit Eklemek için önce yukarıdan bir "Tür" seçiniz. </span>
                                            :
                                            <span className={'text-danger'}>{this.getSelectedDataNameFromState('type')} Türüne Eklenecektir.</span>
                                        } </Form.Label>
                                        <Form.Control disabled={parseInt(this.state.selectedTypeId || 0) === 0} type="text" name='kindName' value={this.state.createForm.kindName} onChange={this.createInputHandler} />
                                    </Form.Group>
                                </Col>

                                <Col lg={3}>
                                    <Form.Group controlId="kindTypeAdd">
                                        <Form.Label><b>Satış Tipi:</b></Form.Label>
                                        <Form.Control disabled={this.checkCreateInputDisabledStatus('kind')}  as="select" name={'kindType'} onChange={this.createInputHandler} value={this.state.createForm.kindType}>
                                            <option value={0}>Satış Tipi Seçiniz</option>
                                            <option value={1}>Kilogram</option>
                                            <option value={2}>Adet</option>
                                        </Form.Control>
                                    </Form.Group>


                                </Col>

                                <Col lg={1}>
                                    <br/>
                                    <Button disabled={this.checkCreateButtonDisableStatus('kind')}  onClick={() => this.createNewData('kind', this.state.selectedTypeId)}  style={{marginTop:10}} variant={'primary'} size={'sm'}><i className={'fa fa-save'}></i></Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>

            </Container>
        )

    }

}

export default DataManager;