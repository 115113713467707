import React, {Component} from 'react';
import axios from "axios";
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import '../Announcements/style.css';
import ReactTable from 'react-table';
import SweetAlert from "sweetalert-react";


class Slider extends Component {


    constructor(props) {
        super(props);

        this.state = {
            sliders: [],
            isFetching: false,
            removePopupShow: false,
            removedRecordId: false,
            removeSuccessPopup: false,
        }

        this.baseUrl = 'https://service.todab.org.tr';

        this.langProps = {
            previousText: 'Geri',
            nextText: 'İleri',
            loadingText: 'Yükleniyor',
            noDataText: 'Kayıt Bulunamadı',
            pageText: 'Sayfa',
            ofText: 'toplam',
            rowsText: 'satır'
        };
    }


    getToken() {
        return sessionStorage.getItem('x-user-token') || false;
    }


    handleOpen(recordId) {
        window.location.href = '/admin/sliders/edit/'+recordId;
    }

    handleNew() {
        window.location.href = '/admin/sliders/create';
    }

    removeRecordHandler(recordId, isForce = false) {
        this.setState({
            removedRecordId: recordId,
            removePopupShow: true,
            removeForce: isForce
        });
    }

    removeRecord(recordId) {

        const params = {
            slider_id : recordId
        };

        axios.post('https://service.todab.org.tr/admin-delete-slider', params, {
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        }).then(response => {
           if (response.data.status) {
               this.setState({
                       removePopupShow:false,
                       removeSuccessPopup: true,
                   },
                   // callback
                   () => {
                       this.state.sliders = this.state.sliders.filter((record) => {
                           return record.id !== this.state.removedRecordId;
                       });
                   });
           }  else {
               this.setState({
                   removePopupShow:false,
                   removeSuccessPopup: false,
               });

               alert('Hata Çıktı !');
           }
        });

    }




    fetchSliders() {
        this.setState({isFetching: true});

        axios.get(`${this.baseUrl}/admin-get-sliders`, {
            headers:
                {
                    'Authorization': `Bearer ${this.getToken()}`
                }
        }).then(response => {
            this.setState({sliders: response.data.sliders, isFetching: false}, () => {
                console.log('THİS:SSTATE', this.state.sliders);
            });
        });
    }


    componentDidMount() {
        this.fetchSliders();
    }


    renderTable() {
        const columns = [
            {
                Header: 'Sıra',
                id: 'sira',
                accessor: 'order'
            },

            {
                Header: 'Başlık',
                id: 'baslik',
                accessor: 'header'
            },

            {
                Header: 'Resim / Video ?',
                id: 'img_type',
                accessor: 'content_type',
            },

            {
                Header: 'İçerik',
                id: 'clr_content',
                accessor: d => d.clear_content.substring(0, 100)
            },

            {
                Header: 'Buton',
                id: 'btn',
                accessor: d => d.button_enable == 1 ? d.button_text : 'Buton Aktif Değil',
            },

            {
                Header: 'Oluşturulma Tarihi',
                accessor: 'created_at'
            },

            {
                Header: 'İşlemler',
                style: {textAlign:'center'},
                accessor: d => {
                    return (<>
                        <Button variant={'primary'} onClick={ () => this.handleOpen(d.id)}> <i className="fa fa-pencil"></i> Düzenle</Button> &nbsp;
                        <Button variant={'danger'} onClick={ () => this.removeRecordHandler(d.id)}><i className="fa fa-trash"></i> Sil</Button>
                    </>)
                },
                id: 'colActions'
            }

        ];

        return (  <ReactTable style={{height:350}} {...this.langProps} minRows={5}
                              data={this.state.sliders}
                              columns={columns}
        />);
    }



    render() {

        if (this.state.isFetching) {
            return (
                <Container>
                    <Row>
                        {this.state.isFetching ? <Col lg={12}>Yükleniyor</Col> : <></>}
                    </Row>

                </Container>
            )
        } else {
            return (
                <Container fluid>

                    <Row style={{marginTop:35}}>
                        <Col lg={12}>
                            <Button variant={'success'} onClick={ () => this.handleNew()}> <i className="fa fa-plus"></i> Yeni Slider Ekle</Button> &nbsp;
                        </Col>
                    </Row>
                    <Row style={{marginTop:20}}>
                        <Col lg={12} style={{marginTop:40}}>

                            <SweetAlert
                                show={this.state.removePopupShow}
                                title="Uyarı"
                                text="Bu Slider Kaydını Silmek İstediğinize Emin misiniz?"
                                showCancelButton
                                confirmButtonText={'Sil'}
                                cancelButtonText={'Vazgeç'}
                                onConfirm={() => this.setState({ removePopupShow: false }, () => this.removeRecord(this.state.removedRecordId))}
                                onCancel={() => this.setState({removePopupShow:false})}
                            />

                            <SweetAlert
                                show={this.state.removeSuccessPopup}
                                title="Bilgilendirme"
                                text="Slider Başarı İle Silindi !"
                                confirmButtonText={'Tamam'}
                                onConfirm={() => this.setState({ removeSuccessPopup: false })}
                            />


                            {this.renderTable()}
                        </Col>

                    </Row>
                </Container>
            )
        }

    }

}

export default Slider;