import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import routes from './../routes/routes';
import PublicLayout from './../layouts/PublicLayout';
import PrivateLayout from './../layouts/PrivateLayout';
import _ from 'lodash';
import history from './../routes/history';

const NotFound  = () =>  <div>Bulunamadı !</div>



class App extends Component {

    constructor (props) {
        super(props);
    }

    render () {
        return (
            <Router history={history}>
                    <Switch>
                        {
                            _.map(routes, (route, key) => {
                                const {component, path, isPrivate} = route;
                                console.log('isPrivate', isPrivate);
                                return (
                                    <Route
                                        exact
                                        path={path}
                                        key={key}
                                        render={ (route) => isPrivate ? <PrivateLayout component={component} route={route}/> : <PublicLayout component={component} route={route} /> }
                                    />
                                )
                            })
                        }

                        <Route component={ NotFound } />
                    </Switch>
            </Router>
        )
    }
}

export default App;
