import {
    START_GET_CONTACT_DATA,
    END_GET_CONTACT_DATA,
    ABORT_GET_CONTACT_DATA,
    START_GET_STOCKS_DATA,
    END_GET_STOCKS_DATA,
    ABORT_GET_STOCKS_DATA,
    START_UPDATE_STOCKS_DATA,
    ABORT_UPDATE_STOCKS_DATA,
    END_UPDATE_STOCKS_DATA,
    RESET_ABORT_STATE_STOCKS_DATA, START_DELETE_STOCKS_DATA, END_DELETE_STOCKS_DATA, ABORT_DELETE_STOCKS_DATA
} from '../constants/user-action-types';

import history from '../../routes/history';

const initialState = {
    isFetching: false,
    contacts: [],
    stocks: [],
    error: false,

    updateError: false,
    deleteError: false,
};



function userReducers(state = initialState, action) {


    switch (action.type) {
        case START_GET_CONTACT_DATA:
            return Object.assign({}, state, {
                isFetching: true,
            });

        case END_GET_CONTACT_DATA:
            console.log('ACTİON PAYLOAD', action.payload);
            return {...state,
                contacts: action.payload.stock_contacts || [],
                isFetching: false
            }

        case ABORT_GET_CONTACT_DATA:
            return {...state, error:true, isFetching:false}

        /** STOCKS DATA CASE'S **/
        case START_UPDATE_STOCKS_DATA:
        case START_GET_STOCKS_DATA:
        case START_DELETE_STOCKS_DATA:
            return {...state, isFetching: true};

        case END_GET_STOCKS_DATA:
            return {...state, isFetching:false, stocks: action.payload.stocks || [] }

        case ABORT_GET_STOCKS_DATA:
            return {...state, error:true, isFetching: false, stocks: []}

        case END_UPDATE_STOCKS_DATA:
                console.log('UPDATE RESPONSE', action.payload);
                return {...state, isFetching:false, error:false, newData: action.payload}

        case ABORT_UPDATE_STOCKS_DATA:
            return {...state, isFetching:false, updateError:true}

        case RESET_ABORT_STATE_STOCKS_DATA:
            return {...state, updateError: false};


        case END_DELETE_STOCKS_DATA:
            console.log('DELETE RESPONSE', action.payload);
            return {...state, isFetching:false, deleteError: false};

        case ABORT_DELETE_STOCKS_DATA:
            return {...state, isFetching:false, deleteError: true};

        default:
            return state;
    }


}



export default userReducers;