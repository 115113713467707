import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row,Col, Card, Table} from 'react-bootstrap';
import {postContactRequests} from './../../redux/actions/user';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class Home extends Component {

    constructor(props) {
        super(props);
    }

    render () {
        return (
           <Container  style={{marginTop:20}}>
               <Row>
                   <Col lg={12}>
                       <h3 align="center">Ana Sayfa</h3>
                       <br/>
                       <br/>
                   </Col>



                   <Col lg={4}>
                       <Card bg="success" text="white" style={{ width: '18rem', cursor:'pointer' }} onClick={() => {
                           window.location.href='/backend/stocks'
                       }}>
                           <Card.Header>Stok Listesi</Card.Header>
                           <Card.Body>
                               <Card.Title>Stok Listesi</Card.Title>
                               <Card.Text>
                                   Stok Eklemek, var olan stoklarınızı güncellemek - silmek için bu bölümü kullanabilirsiniz.
                                   (Tıklayınız)
                               </Card.Text>
                           </Card.Body>
                       </Card>
                   </Col>

                   <Col lg={4}>
                       <Card bg="danger" text="white" style={{ width: '18rem', cursor:'pointer' }} onClick={() => {
                           window.location.href='/backend/new-stock'
                       }}>
                           <Card.Header>Yeni Stok Ekle</Card.Header>
                           <Card.Body>
                               <Card.Title>Stok Ekleyin</Card.Title>
                               <Card.Text>
                                   Yeni Bir Stok Eklemek İçin Lütfen Tıklayınız.
                                   <br/>
                                   <br/>
                                   <br/>
                               </Card.Text>
                           </Card.Body>
                       </Card>
                   </Col>

                   <Col lg={4}>
                       <Card bg="info" text="white" style={{ width: '18rem', cursor:'pointer' }} onClick={() => {
                           window.location.href='/backend/contact-requests'
                       }}>
                           <Card.Header>İletişim İstekleri</Card.Header>
                           <Card.Body>
                               <Card.Title>İletişim İstekleri</Card.Title>
                               <Card.Text>
                                   Stoklarınıza gelen iletişim isteklerini görüntülemek için bu bölümü kullanabilirsiniz.
                                   (Tıklayınız)

                               </Card.Text>
                           </Card.Body>
                       </Card>
                   </Col>
               </Row>
           </Container>
        );
    }

}



const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getContacts: () => dispatch(postContactRequests())
    }
}


Home = connect(mapStateToProps, mapDispatchToProps)(Home);

export default Home;