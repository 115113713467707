import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../components/Templates/Header';
import { checkIsAdmin, checkLogin } from '../redux/actions/login';
import connect from 'react-redux/es/connect/connect';


class PublicLayout extends Component {

    componentDidMount() {
        this.props.checkLogin();
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.login.isAuthenticated && this.props.login.isAuthenticated === false) {
            let isAdmin = checkIsAdmin(nextProps.login);
            if (isAdmin) {
                window.location.href = '/admin/home';
            } else {
                window.location.href = '/backend/home';
            }

        }
    }

    render() {
        const Component = this.props.component;
        const route = this.props.route;

        return (
            <>
                <Component route={route}/>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkLogin: () => dispatch(checkLogin())
    }
}

PublicLayout = connect(mapStateToProps, mapDispatchToProps)(PublicLayout);

export default PublicLayout;