import React, {Component} from 'react';
import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import './style.css';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

class News extends Component {

    constructor (props) {
        super(props);

        this.state = {
            news: [],
            isFetching: false,
            removePopupShow: false,
            removeSuccessPopup: false,
            selectedNewsId: null,

        }

        this.baseUri = 'https://service.todab.org.tr';

    }


    getNews() {
        const token = sessionStorage.getItem('x-user-token') || false;
        this.setState({isFetching: true});

        axios.get(`${this.baseUri}/get-admin-news`, {
            params: {type: 'news'},
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => this.setState({news: response.data.data}),
            error => console.log('ERROR', error)
        );

        this.setState({isFetching: false});
    }


    removeNews(newsId) {
        const token = sessionStorage.getItem('x-user-token') || false;
        this.setState({isFetching: true});

        axios.post(`${this.baseUri}/admin-delete-news`, {news_id: this.state.selectedNewsId}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(
            response => {
              if (response.data.status) {
                  let news = [...this.state.news];

                  news = news.filter((news) => {
                      return news.id !== this.state.selectedNewsId
                  });

                  this.setState({
                      news,
                      removePopupShow:false,
                      removeSuccessPopup: true,
                  });
              }
            },
            error => console.log('ERROR', error)
        );
    }

    renderNews() {
        let list = [];
        this.state.news.map((news) => {
            let newSubject = news.content.replace(/<[^>]*>?/gm, '');
            let image = 'https://service.todab.org.tr/newsImages/'+news.main_image;

            list.push(<Card style={{ width: '100%', marginBottom:20 }}>
                <Row>
                    <Col lg={3}>
                        <div style={{background:'url('+image+')',
                            backgroundRepeat:'no-repeat',
                            width:300, height:250, backgroundSize:'contain'}} />
                    </Col>
                    <Col lg={7}>
                        <Card.Body>
                            <Card.Title>{news.subject}</Card.Title>
                            <Card.Text>
                                {newSubject}
                            </Card.Text>
                        </Card.Body>
                    </Col>
                    <Col lg={2} style={{alignSelf:'center'}}>
                        <Button variant="primary" className={'form-control'} onClick={() => {
                            window.location.href = '/admin/news/edit/'+news.id;
                        }}>Düzenle</Button>
                        <br/><br/>
                        <Button variant="danger" className={'form-control'} onClick={() => {
                            this.setState({selectedNewsId: news.id, removePopupShow:true});
                        }}>Sil</Button>
                    </Col>
                </Row>


            </Card>)
        });

        return list;
    }


    componentDidMount() {
        this.getNews();
    }

    render() {
        return (
            <Container style={{marginTop:20}}>

                <SweetAlert
                    show={this.state.removePopupShow}
                    title="Uyarı"
                    text="Haber Kaydını Silmek İstediğinize Emin misiniz?"
                    showCancelButton
                    confirmButtonText={'Sil'}
                    cancelButtonText={'Vazgeç'}
                    onConfirm={() => this.setState({ removePopupShow: false }, () => this.removeNews(this.state.selectedNewsId))}
                    onCancel={() => this.setState({removePopupShow:false})}
                />

                <SweetAlert
                    show={this.state.removeSuccessPopup}
                    title="Bilgilendirme"
                    text="Haber Başarı İle Silindi !"
                    confirmButtonText={'Tamam'}
                    onConfirm={() => this.setState({ removeSuccessPopup: false })}
                />


                <Row>
                    <Col lg={5}>
                        <h1>Haber Yönetimi</h1>
                    </Col>
                    <Col lg={5}>
                        <Button style={{marginTop:10}} variant={'success'} onClick={() => {
                            window.location.href='/admin/news/create';
                        }}><i className={'fa fa-plus'}></i> Yeni Haber Ekle</Button>
                    </Col>
                </Row>

                <Row>
                    {this.renderNews()}

                    {
                        this.state.news.length == 0 &&
                        <h3 style={{marginLeft:20}}>Hiç Kayıt Bulunamadı !</h3>
                    }
                </Row>
            </Container>
        )
    }
}


export default News;

