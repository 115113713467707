import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import configureStore from "./redux/store/index";
import './index.css';
import App from "./components/App.jsx";
import * as serviceWorker from './serviceWorker';
import history from './routes/history';

const store = configureStore({}, history);

ReactDOM.render(  <Provider store={store}>
    <App />
    </Provider>
    , document.getElementById('root'));

store.subscribe(() => {
    let x = store.getState();
    console.log('DEGİSTİ', x);
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
