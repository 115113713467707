import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Templates/Header';
import {connect} from 'react-redux';
import {checkLogin} from './../redux/actions/login';
import { Spinner } from 'react-bootstrap';


class PrivateLayout extends Component {

    constructor(props) {
    	super(props);
        this.props.checkLogin().then(
            () => {
                if (!this.props.login.isAuthenticated) {
                    window.location.href = '/login';
                }
            }
        );
    }

    componentWillUpdate(nextProps) {
        if (this.props.login.isAuthenticated && nextProps.login.isAuthenticated === false) {
            window.location.href = '/login';
        }
    }

    render() {
    	if (this.props.login.isAuthenticated) {
		    const Component = this.props.component;
		    const route = this.props.route;
		    return (
			    <>
				    <Header/>

				    <Component route={route}/>

			    </>
		    );
	    } else {
		    return (<div align="center"  style={{
			    position: 'absolute', left: '50%', top: '50%',
			    transform: 'translate(-50%, -50%)'
		    }}> <Spinner animation="border" /></div>);
	    }

    }
}


const mapStateToProps = state => {
    return {
        login: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkLogin: () => dispatch(checkLogin())
    }
}

PrivateLayout = connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);

export default PrivateLayout;
