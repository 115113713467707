import axios from 'axios';
import {
    START_GET_CONTACT_DATA,
    END_GET_CONTACT_DATA,
    ABORT_GET_CONTACT_DATA,
    START_GET_STOCKS_DATA, END_GET_STOCKS_DATA, ABORT_GET_STOCKS_DATA,
    START_UPDATE_STOCKS_DATA, END_UPDATE_STOCKS_DATA, ABORT_UPDATE_STOCKS_DATA,
    RESET_ABORT_STATE_STOCKS_DATA, START_DELETE_STOCKS_DATA, END_DELETE_STOCKS_DATA, ABORT_DELETE_STOCKS_DATA
} from '../constants/user-action-types';
import { abortLoginRequest, startLoginRequest } from './login';


const API_BASE_URL = 'https://service.todab.org.tr';

/********* Contact Requests Functions ************/
export function startGetContactRequest(payload) {
    return { type: START_GET_CONTACT_DATA, payload };
}

export function endGetContactRequest(payload) {
    return { type: END_GET_CONTACT_DATA, payload};
}

export function abortGetContactRequest(payload) {
    return { type: ABORT_GET_CONTACT_DATA, payload}
}

/**
 * Action (Get Contact Requests)
 * @return {function(*): Promise<AxiosResponse<T> | never>}
 */
export function postContactRequests() {

    return function (dispatch) {

        dispatch(startLoginRequest());

        const token = sessionStorage.getItem('x-user-token');

        // if has not token, abort request
        if (token === null) {
            return dispatch(abortGetContactRequest());
        }

        return axios.get(`${API_BASE_URL}/get-stock-contacts`, {headers: { 'Authorization': `Bearer ${token}`}})
                    .then(
                        response =>  { dispatch(endGetContactRequest(response.data))},
                        error => dispatch(abortGetContactRequest(error))
                    )
    }

}





/*** Stock Functions ***/
export function startGetStocksRequest(payload) {
    return { type: START_GET_STOCKS_DATA, payload };
}

export function endGetStocksRequest(payload) {
    return { type: END_GET_STOCKS_DATA, payload};
}

export function abortGetStockRequests(payload) {
    return { type: ABORT_GET_STOCKS_DATA, payload}
}

export function getStocksRequest() {

    return function (dispatch) {
        dispatch(startGetStocksRequest());

        const token = sessionStorage.getItem('x-user-token');

        // if has not token, abort request
        if (token === null) {
            return dispatch(abortGetStockRequests());
        }

        return axios.get(`${API_BASE_URL}/get-stocks`, {headers: {'Authorization': `Bearer ${token}`}})
            .then(
                response => dispatch(endGetStocksRequest(response.data)),
                error => dispatch(abortGetStockRequests(error))
            )
    }
}




/** UPDATE STOCKS **/
export function startUpdateStocksRequest(payload) {
    return { type: START_UPDATE_STOCKS_DATA, payload};
}

export function endUpdateStocksRequest (payload) {
    return { type: END_UPDATE_STOCKS_DATA, payload};
}

export function abortUpdateStocksData (payload) {
    console.log('HATA CIKTI !');
    return { type: ABORT_UPDATE_STOCKS_DATA, payload};
}


export function resetAbortStateStocksData(payload) {
    return { type: RESET_ABORT_STATE_STOCKS_DATA, payload};
}

export function updateStockRequest(stockId, formData) {

    return function (dispatch) {
        dispatch(startUpdateStocksRequest());

        const token = sessionStorage.getItem('x-user-token');

        // if has not token, abort request
        if (token === null) {
            return dispatch(abortUpdateStocksData());
        } else {
            console.log('STOCK ID', stockId, 'FORM DATA', formData);

            return axios.post(`${API_BASE_URL}/update-user-stock`, formData, {headers: {'Authorization': `Bearer ${token}`}})
                        .then(
                            response => dispatch(endUpdateStocksRequest(response.data)),
                            error => dispatch(abortUpdateStocksData(error))
                        )
        }

    }
}




/** DELETE STOCKS **/
export function startDeleteStocksRequest(payload) {
    return { type: START_DELETE_STOCKS_DATA, payload};
}

export function endDeleteStocksRequest (payload) {
    return { type: END_DELETE_STOCKS_DATA, payload};
}

export function abortDeleteStocksRequest (payload) {
    console.log('HATA CIKTI !');
    return { type: ABORT_DELETE_STOCKS_DATA, payload};
}

export function deleteStockRequest(stockId) {

    return function (dispatch) {
        dispatch(startDeleteStocksRequest());

        const token = sessionStorage.getItem('x-user-token');

        // if has not token, abort request
        if (token === null) {
            return dispatch(abortLoginRequest('token null geldi'));
        } else {
            return axios.post(`${API_BASE_URL}/delete-user-stock`, stockId, {headers: {'Authorization': `Bearer ${token}`}})
                    .then(
                        response => dispatch(endDeleteStocksRequest(response.data)),
                        error => dispatch(abortDeleteStocksRequest(error))
                    );
        }


    }
}


export function getUserToken() {
    return sessionStorage.getItem('x-user-token') || false;
}